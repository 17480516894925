import React, {useState, useEffect, useRef} from "react"

import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button,
    Label,
    CardImg,
    Modal,
    Table
} from "reactstrap";
import Moment from 'react-moment';
import { Delete,Edit, Visibility, RocketLaunch, ArrowBack, Send } from '@mui/icons-material';
import {Box, IconButton, Tooltip} from '@mui/material';
import {AvForm, AvField} from "availity-reactstrap-validation"
import CustomModal from "../Common/CustomModal"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import * as Session from "../../helpers/session_helper"
import userAvatar from "../../assets/images/users/avatar.png"

import Axios from "../../helpers/axios_helper"
import * as SHelper from "../../helpers/session_helper"
import {useHistory} from "react-router-dom"
import {toast} from 'react-toastify/dist/react-toastify';
import mediaImage from "../../assets/images/media.jpeg";
import './discussion.scss'

const moment = require('moment');

const Model = (props) => {
    const history = useHistory();

    const [descriptionModal, setDescriptionModal] = useState(false);
    const [discussion, setDiscussion] = useState([]);
    const [message, setMessage] = useState('');
    const [sessionUser, setSessionUser] = useState(false);
    const messagesEndRef = useRef(null);
    const messagesContainerRef = useRef(null);
    const [groupMessage, setGroupMessage] = useState([]);

    const scrollToBottom = () => {
        if(messagesContainerRef.current){
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
        }
    };

    //#region topic
    const [discussionTopicId, setDiscussionTopicId] = useState(-1);
    const [discussionTopicIdx, setDiscussionTopicIdx] = useState(-1);
    const [showCreateNew, setShowCreateNew] = useState(false);
    const [courseDiscussionTopicTitle, setCourseDiscussionTopicTitle] = useState('');
    const [courseDiscussionTopicDescription, setCourseDiscussionTopicDescription] = useState('');
    const [courseDiscussionTopics, setCourseDiscussionTopics] = useState([]);
    const [selecetdCourseDiscussionTopicId, setSelecetdCourseDiscussionTopicId] = useState(0)
    const [selecetdCourseDiscussionTopicTitle, setSelectedCourseDiscussionTopicTitle] = useState('')

    
    const handleSubmit = async (event, errors, values) => {
        window.sLoader();
        if (!props.courseId) {
            toast.error(`Course is required !`, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
        }
        if (!props.moduleId) {
            toast.error(`Module is required !`, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
        }
        else {
          var updateData=courseDiscussionTopics;
          updateData.forEach(element => {
            element.courseId=props.courseId;
            element.moduleId=props.moduleId;
            element.courseDiscussionTopicIsActive=element.courseDiscussionTopicIsActive==1?true:false;
            delete element.createdAt;
          });
    
          const jsonData = {
            topics:updateData
          }
    
          await Axios.post("courseDiscussion/topic/bulk", jsonData)
            .then(async (response) => {
              if (response.data.status === 200) {
                getDiscussionTopicList();
                toast.success('Course Classes Created/Updated successfully!', {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                });
              } else {
                toast.error(response.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                });
              }
            })
            .catch((e) => {
              toast.error(e.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
            })
    
        }
        window.sLoader();
    }

    const editDiscussionTopic = (id,idx) =>{
        setDiscussionTopicId(id);
        setDiscussionTopicIdx(idx);
        setCourseDiscussionTopicTitle(courseDiscussionTopics[idx].courseDiscussionTopicTitle);
        setCourseDiscussionTopicDescription(courseDiscussionTopics[idx].courseDiscussionTopicDescription);
        setShowCreateNew(true);
    }

    const cancelDiscussionTopicEdit = () =>{
        setDiscussionTopicId(-1);
        setDiscussionTopicIdx(-1);
        setCourseDiscussionTopicTitle('');
        setCourseDiscussionTopicDescription('');
        setShowCreateNew(false);
    }

    const addDiscussionTopic = () =>{
        if(discussionTopicIdx>-1){
            courseDiscussionTopics[discussionTopicIdx].id=discussionTopicId;
            courseDiscussionTopics[discussionTopicIdx].courseDiscussionTopicTitle=courseDiscussionTopicTitle;
            courseDiscussionTopics[discussionTopicIdx].courseDiscussionTopicDescription=courseDiscussionTopicDescription;
        }
        else{
            courseDiscussionTopics.push({
                courseDiscussionTopicTitle:courseDiscussionTopicTitle,
                courseDiscussionTopicDescription:courseDiscussionTopicDescription
            });
        }
        setCourseDiscussionTopics(courseDiscussionTopics);
        cancelDiscussionTopicEdit();
        handleSubmit();
    }

    const getDiscussionTopicList = async () =>{
        window.sLoader();
        setCourseDiscussionTopics([])
        await Axios.get(`/courseDiscussion/topic/${props.courseId}&${props.moduleId}`)
              .then((response) => {
                  if (response.data) {
                    setCourseDiscussionTopics(response.data.data);
                  }
                  else {
                    setCourseDiscussionTopics([])
                  }
              });
        window.hLoader();
      }
    //#endregion
    useEffect(() => {
        scrollToBottom();
    }, [groupMessage]);

    const sendMessage = async () =>{

        if(props.moduleId<1){
            toast.error('Please select subject/module to join group disccssion !', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });

            return;
        }
        
        // alert(message);
        setMessage('');
        
        const jsonData = {
            courseDiscussionTopicId : selecetdCourseDiscussionTopicId,
            userDetailId : sessionUser.userDetailId,
            courseDiscussionDetails : message
          }
      
          await Axios.post("/courseDiscussion/addMessage", jsonData)
            .then(async (response) => {
              if (response.data.status === 200) {
                setGroupMessage(response.data.data);
                window.scrollTo("wrapper");
              } else {
                toast.error(response.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                });
              }
            })
            .catch((e) => {
              toast.error(e, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
            })
    }

    const getTopicDiscussions = async (selecetdCourseDiscussionTopicId,selecetdCourseDiscussionTopicTitle)=>{
        
        setSelecetdCourseDiscussionTopicId(selecetdCourseDiscussionTopicId);
        setSelectedCourseDiscussionTopicTitle(selecetdCourseDiscussionTopicTitle);

        await Axios.get(`/courseDiscussion/getGroupDiscussions/${selecetdCourseDiscussionTopicId}`)
            .then(async (response) => {
            if (response.data.status === 200) {
                setGroupMessage(response.data.data);
            } else {
                toast.error(response.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                });
              }
            })
            .catch((e) => {
              toast.error(e, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
            })
    }

    const showDiscussionDescription = async (id)=>{
        window.sLoader();
        await Axios.get("/courseDiscussion/topic/id/"+id)
        .then((response) => {
            if (response.data.status==200) {
                setDiscussion(response.data.data);
                setDescriptionModal(true);
            }
        });
        window.hLoader();
    }

    useEffect(async() => {
        if(props.courseId>0 && props.moduleId>0){
            const data = Session.getUser();
            setSessionUser(data);
            getDiscussionTopicList();
        }
    }, [props.courseId, props.moduleId])

    return (
        <>
        
        <Row>
            {(!props.id || props.id === 0) &&
                <Col xl="12">
                
                <Card>
                    <CardTitle style={{ fontSize: "18px", textAlign: "center", opacity:"0.7" }}>Group Discussion</CardTitle>
                    
                    <CardBody style={{ paddingTop: "0.5rem" }}>
                    {showCreateNew && 
                    <AvForm className="needs-validation" onSubmit={handleSubmit}>
                    {SHelper.userHasEditPermission() &&
                    <>
                        <Col md="12">
                        <Row>
                            <Col md="12">
                            <div className="mb-3">
                                <Label>Title</Label>
                                <AvField
                                name="courseDiscussionTopicTitle"
                                value={courseDiscussionTopicTitle}
                                onChange={(e)=>{setCourseDiscussionTopicTitle(e.target.value);}}
                                type="text"
                                className="form-control"
                                />
                            </div>
                            </Col>
                            <Col md="12">
                            <div className="mb-3">
                                <Label>Description</Label>
                                <AvField
                                name="courseDiscussionTopicDescription"
                                value={courseDiscussionTopicDescription}
                                onChange={(e)=>{setCourseDiscussionTopicDescription(e.target.value);}}
                                type="textarea"
                                className="form-control"
                                />
                            </div>
                            </Col>
                        </Row>
                        </Col>
                        <div className="actions clearfix pt-2">
                            <div className="button-items">
                            <Button
                                style={{ float: "right", marginLeft: "10px" }}
                                type="button"
                                color="primary"
                                onClick={addDiscussionTopic}
                                >{ discussionTopicIdx>-1 ? "Update":"Add"}</Button>
                                { discussionTopicIdx >-1 && 
                                <Button
                                    style={{ float: "right" }}
                                    type="button"
                                    color="danger"
                                    onClick={()=>{setShowCreateNew(false); cancelDiscussionTopicEdit();}}
                                >Cancel Edit</Button>}
                            </div>
                            {showCreateNew && discussionTopicIdx ==-1 &&
                                <Button
                                    onClick={()=>{setShowCreateNew(false); cancelDiscussionTopicEdit();}}
                                    style={{ float: "left" }}
                                    type="button"
                                    color="danger"
                                    >Cancel</Button>}
                        </div>
                        </>}
                    </AvForm>
                    }
                    {!showCreateNew && SHelper.userHasEditPermission() && selecetdCourseDiscussionTopicId<1 &&
                        <div className="actions clearfix mb-3">
                            <Button
                                onClick={()=>setShowCreateNew(true)}
                                style={{ float: "left" }}
                                type="button"
                                color="primary"
                                >Create New Topic</Button>
                        </div>}
                    {!showCreateNew && selecetdCourseDiscussionTopicId<1 &&
                        <Col md={12}>
                            <div className="table-responsive">
                                <Table className="table mb-0">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Title</th>
                                            {/* <th>Start Datetime</th> */}
                                            {/* <th>End Datetime</th> */}
                                            <th>Description</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {courseDiscussionTopics.map((item,i)=>{
                                            return (
                                                <tr key={i}>
                                                    <th scope="row">{i+1}</th>
                                                    <td>{item.courseDiscussionTopicTitle}</td>
                                                    {/* <td><Moment date={item.courseStartDatetime} format="YYYY-MM-DD h:mm A"/></td> */}
                                                    {/* <td><Moment date={item.courseEndDatetime} format="YYYY-MM-DD h:mm A"/></td> */}
                                                    {/* <td>{item.courseDiscussionTopicDescription.length>20?`${item.courseClassDescription.substring(0, 20)} ...`:item.courseDiscussionTopicDescription??"N/A"}</td> */}
                                                    <td>
                                                        <Box style={{cursor:"pointer"}} sx={{ display: discussionTopicIdx === i ? 'none' : 'flex' }} onClick={() => { showDiscussionDescription(item.id) }}>
                                                        <Visibility /> 
                                                        {" "}
                                                        <Tooltip arrow placement="left" title={ 'See Details'}>
                                                            <IconButton>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Box>
                                                    </td>
                                                    <td>
                                                        <Box sx={{ display: discussionTopicIdx===i?'none':'flex' }}>
                                                            {SHelper.userHasEditPermission() && 
                                                            <Tooltip arrow placement="left" title="Edit">
                                                            <IconButton onClick={() => {editDiscussionTopic(item.id,i)}}>
                                                                <Edit />
                                                            </IconButton>
                                                            </Tooltip>}
                                                            
                                                            {/* {SHelper.userHasEditPermission() && 
                                                            <Tooltip arrow placement="left" title="Delete">
                                                            <IconButton style={{color:"red"}} onClick={() => {deleteDiscussionTopic(item.id,i)}}>
                                                                <Delete />
                                                            </IconButton>
                                                            </Tooltip>} */}

                                                            <Tooltip arrow placement="left" title="Join Discussion">
                                                            <IconButton style={{color:"blue"}} onClick={() => {getTopicDiscussions(item.id,item.courseDiscussionTopicTitle)}}>
                                                                <RocketLaunch />
                                                            </IconButton>
                                                            </Tooltip>

                                                        </Box>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>}
                    </CardBody>
                    
                </Card>
                </Col>
            }
        </Row>
        
        {selecetdCourseDiscussionTopicId>0 &&
        <div className="app" style={{minWidth:"-webkit-fill-available"}}>
            <div className="wrapper">
                <div className="chat-area" ref={messagesContainerRef} >
                    <div className="chat-area-header">
                        <div className="chat-area-title">
                            <IconButton style={{color:"red"}} title="Get Back to Topic List" onClick={() => {setSelecetdCourseDiscussionTopicId(0)}}>
                                <ArrowBack />
                            </IconButton>
                            Group Discussion (<span style={{color:"rgb(47, 74, 185)"}}>{selecetdCourseDiscussionTopicTitle}</span>)
                        </div>
                    </div>
                    <div className="chat-area-main">
                        
                        {groupMessage.length>0 && groupMessage.map((item,i)=>{
                            return (<>
                            
                                <div className={item.userDetailId==sessionUser.userDetailId?"chat-msg owner":"chat-msg"} key={i}>
                                    <div className="chat-msg-profile">
                                        <img
                                            className="chat-msg-img"
                                            src={userAvatar}
                                            alt=""/>
                                        <div className="chat-msg-date">{`${item.userName} (${item.roleName}) - ${item.createdAt}`}</div>
                                    </div>
                                    <div className="chat-msg-content">
                                        <div className="chat-msg-text">{item.message}</div>
                                    </div>
                                </div>
                            </>)
                        })}

                        <div ref={messagesEndRef} />
                    </div>
                    <div className="chat-area-footer">
                        <input type="text" placeholder="Type something here..." value={message} onChange={e=>setMessage(e.target.value)}/>
                        <IconButton style={{color:"red"}} title="Send Message" onClick={() => {sendMessage()}}>
                            <Send />
                        </IconButton>
                    </div>
                </div>
            </div>
        </div>
        }
        <Modal
            size="lg"
            isOpen={descriptionModal}
            centered={true}>

            <div className="modal-header">
              <h5 className="modal-title mt-0">Topic Description</h5>
              <button
                type="button"
                onClick={()=>{setDescriptionModal(false)}}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              > 
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p><div dangerouslySetInnerHTML={{ __html: discussion.courseDiscussionTopicDescription }} style={{padding:"10px"}}></div></p>
            </div>
          </Modal>
        </>
       
    )
}

export default Model
