import React,{useState,useEffect} from "react"
import MainModel from "./model"
import { useLocation,useHistory } from 'react-router-dom';
import queryString from 'query-string';

const Create = (props) => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = queryString.parse(location.search);
  const [ newEnrollment, setNewEnrollment ] = useState(false);
  useEffect(()=>{
    if(queryParams.status=="new")setNewEnrollment(true);
    else if(queryParams.status)history.push("/page-500");

  },[queryParams])

  return (
    <>
      <div className="page-content">
          <MainModel status={newEnrollment} requestData={queryParams.requestData} loginBackUrl={queryParams.loginUrl}/>
      </div>
    </>
  )
}

export default Create
