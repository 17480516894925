import React, { useMemo, useState, useEffect, useRef } from "react"
import {
    Row,
    Col,
    Input,
    Card,
    Button as RButton,
    CardBody,
    CardTitle,
    Label,
    CardImg,
    Table,
} from "reactstrap";
import * as helper from '../../utils/helper'
import { Delete, Edit, ChangeCircle, Download, List } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Typography,
  Tooltip,
} from '@mui/material';
import MaterialReactTable from 'material-react-table';
import { AvForm, AvField } from "availity-reactstrap-validation"
import * as CHelper from "../../helpers/custom_helper"
import Axios from "../../helpers/axios_helper"
import * as SHelper from "../../helpers/session_helper"
import { useHistory } from "react-router-dom"
import { toast,Slide } from 'react-toastify/dist/react-toastify';
import CustomModal from "../Common/CustomModal"

const Model = (props) => {
    const [courseClasses, setCourseClasses] = useState([]);
    const [showCreateNewClass, setShowCreateNewClass] = useState(false)
    const [courseClassIdx, setCourseClassIdx] = useState(-1);
    
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    const [columnFilters, setColumnFilters] = useState([]);
    const [columnFilterFns, setColumnFilterFns] = useState({
      courseClassName: 'contains',
      courseStartDatetime: 'equal',
      courseEndDatetime: 'equal'
    });
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const tableInstanceRef = useRef(null);
    const [pagination, setPagination] = useState({
      pageIndex: 0,
      pageSize: 5
    });

    
     //#region Datatable

    const columns = useMemo(
      () => {
        const iColums = [
        {
          size:50,
          accessorKey: 'sl',
          header: "#SL",
          Cell: ({ cell, row }) => (
            <Typography variant='h7'>{row.index+1}</Typography>
          ),
          enableColumnFilterModes: false,
          enableColumnActions:false,
          enableSorting: false
        },
        {
          accessorKey: 'courseClassName',
          header: "Name",
          Cell: ({ cell, row }) => (
            <Typography variant='h7'>{cell.getValue()}</Typography>
          ),
          enableColumnFilterModes: true,
          filterFn: 'contains',
          columnFilterModeOptions: ['contains'],
        },
        {
          accessorKey: 'courseStartDatetime',
          header: "Start",
          Cell: ({ cell, row }) => (
            <Typography variant='h7'>{helper.formatDate(row.original.courseStartDatetime)}</Typography>
          ),
          enableColumnFilterModes: true,
          filterFn: 'contains',
          columnFilterModeOptions: ['contains'],
        },
        {
          accessorKey: 'courseEndDatetime',
          header: 'End',
          Cell: ({ cell, row }) => (
            <Typography variant='h7'>{helper.formatDate(row.original.courseEndDatetime)}</Typography>
          ),
          enableColumnFilterModes: true,
          filterFn: 'contains',
          columnFilterModeOptions: ['contains'],
        },
        {
          accessorKey: 'courseClassIsActive',
          header: "Status",
          Cell: ({ cell, row }) => (
            // <Typography variant='h7'>{row.original.startDatetime}</Typography>
            <span className={checkDateRange(row.original.courseStartDatetime,row.original.courseEndDatetime)[0]}>{checkDateRange(row.original.courseStartDatetime,row.original.courseEndDatetime)[1]}</span>
          ),
          enableColumnFilterModes: false,
          enableColumnActions:false,
          enableSorting: false
        },
      ]
      return iColums;
    },
      [],
    );

    const findFilterCondition = (name) => {
      var condition = '';
      if (columnFilterFns[name]) {
        condition = columnFilterFns[name];
      }
      return condition;
    }

    function checkDateRange(fromDate, toDate) {
      const currentDate = new Date();
      const startDate = new Date(fromDate);
      const endDate = new Date(toDate);

      if (currentDate >= startDate && currentDate <= endDate) {
          return ["badge badge-soft-success font-size-12","Ongoing",true]; //'Current date is within the range'
      } else if (currentDate > startDate) {
          return ["badge badge-soft-danger font-size-12","Completed",false]; //'Current date is before the range'
      } else {
          return ["badge badge-soft-warning font-size-12","Upcoming", true]; //'Current date is after the range'
      }
    }

    const fetchDataFromAPI = async (reset = false) => {
      
      window.sLoader();
      if (!courseClasses.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      let filterQuery = '';
      let sorterQuery = '';

      if (!reset) {
        if (columnFilters.length > 0) {
          columnFilters.forEach(element => {
            if (element.id === 'invoicedate' || element.id === 'duedate') {
              // filterQuery += `&filter=${element.id},${findFilterCondition(element.id)},${AuthVerify.getDate(element.value)}`;
            }
            else {
              filterQuery += `&filter=${element.id},${findFilterCondition(element.id)},${element.value}`;
            }
          });
        }
        if (sorting.length > 0) {
          sorting.forEach(element => {
            sorterQuery += `&sortby=${element.id}&orderby=${element.desc ? 'desc' : 'asc'}`;
          });
        }
      } else {
        setPagination({
          pageIndex: 0,
          pageSize: 5
        });
        tableInstanceRef.current.resetRowSelection();
      }

      const url = `courseClass/listByCourse/${props.courseId}/${props.moduleId}?limit=${pagination.pageSize}&offset=${pagination.pageIndex * pagination.pageSize}${sorterQuery}${filterQuery}`;
      // let url = `/page/list`;
      await Axios.get(url)
        .then(function (response) {
          setIsError(false);
          setIsLoading(false);
          setIsRefetching(false);
          if (response && response.data?.data) {
            const filteredData = response.data.data.filter(element => 
                !checkDateRange(element.courseStartDatetime, element.courseEndDatetime)[2]
            );
            setCourseClasses(filteredData);
            setRowCount(response.data.recordsTotal);
          }
          else {
            toast.error(response.statusText ?? "Data fetching failed !", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
              transition: Slide,
            });
          }
        })
        .catch(function (error) {
          // navigate('/');
        });
        window.hLoader();
    };

    useEffect(() => {
      if(props.courseId && props.moduleId); 
      fetchDataFromAPI();
    }, [
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
      props.courseId,
      props.moduleId
    ]);

    //#endregion



    
    function checkDateRange(fromDate, toDate) {
      const currentDate = new Date();
      const startDate = new Date(fromDate);
      const endDate = new Date(toDate);

      if (currentDate >= startDate && currentDate <= endDate) {
          return ["badge badge-soft-success font-size-12","Running",true]; //'Current date is within the range'
      } else if (currentDate > startDate) {
          return ["badge badge-soft-danger font-size-12","Completed",false]; //'Current date is before the range'
      } else {
          return ["badge badge-soft-warning font-size-12","Upcoming",false]; //'Current date is after the range'
      }
    }

    return (
        <Row>
          <Col xl="12">
              <Card>
                <CardTitle style={{ fontSize: "18px", textAlign: "center", opacity:"0.7" }}>Attendances</CardTitle>
                <CardBody style={{ paddingTop: "0.5rem" }}>
                {!showCreateNewClass &&
                  <Col md={12}>
                      <MaterialReactTable
                      columns={columns}
                      data={courseClasses}
                      enableColumnActions={false}
                      enableGlobalFilter={false}
                      enableColumnFilterModes
                      initialState={{
                      showColumnFilters: false
                      }}
                      manualFiltering
                      manualPagination
                      manualSorting
                      onColumnFilterFnsChange={setColumnFilterFns}
                      onColumnFiltersChange={setColumnFilters}
                      onGlobalFilterChange={setGlobalFilter}
                      onPaginationChange={setPagination}
                      onSortingChange={setSorting}
                      tableInstanceRef={tableInstanceRef}
                      rowCount={rowCount}
                      enableEditing
                      state={{
                      columnFilters,
                      columnFilterFns,
                      globalFilter,
                      isLoading,
                      pagination,
                      showAlertBanner: isError,
                      showProgressBars: isRefetching,
                      sorting,
                      }}
                      positionActionsColumn="last"
                      displayColumnDefOptions={{
                      'mrt-row-actions': {
                          header: 'Action',
                      },
                      }}

                      renderRowActions={({ row, table }) => (
                        <Box sx={{ display: courseClassIdx===row.index?'none':'flex' }}>
                            <Tooltip arrow placement="left" title="Download">
                            <IconButton style={{color:"brown"}} onClick={() => {alert("Paid Version Needed")}}>
                                <List />
                            </IconButton>
                            </Tooltip>
                        </Box>
                      )}
                  />
                  </Col>
                }
                </CardBody>
              </Card>
          </Col>
        </Row>
    )
}

export default Model
