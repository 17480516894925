import React,{useState,useEffect} from "react"
import MainModel from "./model"
import Breadcrumbs from "../../components/Common/Breadcrumb"

const Create = (props) => {

  return (
    <>
      <div className="page-content">
          <Breadcrumbs title="Header Image" breadcrumbItem="Update" />
          <MainModel/>
      </div>
    </>
  )
}

export default Create
