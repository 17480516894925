module.exports = {
  applicationInfo:{
    // appName: "PGDSCM, BIM",
    appName: "Edu-Smart",
    devOrgName: "DataCo Systems Limited",
    devOrgLink: "http://www.bim.gov.bd/"
  },
  applicationSettings:{
    developmentBaseURL : "https://api.sustainability.com.bd/api/",//"https://bim-api.braintechsolution.com/api/",//"http://localhost:3334/api/",//"https://bim-api.braintechsolution.com/api/",//""https://api.sustainability.com.bd/api/",//"https://api.supplychain.com.bd/api/",//"https://bim-api.braintechsolution.com/api/",//"http://localhost:3334/api/",//"
    productionBaseURL : "https://api.sustainability.com.bd/api/",//"https://bim-api.braintechsolution.com/api/",//"http://localhost:3334/api/",//"https://bim-api.braintechsolution.com/api/",//""https://api.sustainability.com.bd/api/",//"https://api.supplychain.com.bd/api/",//"https://api.sustainability.com.bd/api/",//"https://braintechsolution.com:3331/api/",//"http://localhost:3334/api/",//"
    session_name : "Supplychain",
    secretKey : "@Shik"
  },
  zoomSettings:{
    baseURL : "http://localhost:3001/",
  }
}


