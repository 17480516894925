import React, { useEffect, useState, useRef } from "react"

import { Row, Col, Alert, Container } from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

import logo from "../../assets/images/supplychain/logo.jpg"
import * as Config from "../../config"
import Axios from "../../helpers/axios_helper"
import * as Session from "../../helpers/session_helper"
import { useLocation,useHistory } from 'react-router-dom';
import queryString from 'query-string';
import avatar from "../../assets/images/users/avatar.jpg"

const Participant = () => {

  const history = useHistory()
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const [notification, setNotification] = useState('');
  const called = useRef(false);

  const [photo, setPhoto] = useState(avatar);
  const [firstname, setFirstname] = useState('...');
  const [middlename, setMiddlename] = useState('...');
  const [lastname, setLastname] = useState('...');
  const [course, setCourse] = useState('...');
  const [session, setSession] = useState('...');
  const [mobile, setMobile] = useState('...');
  const [email, setEmail] = useState('...');
  const [isActive, setIsActive] = useState(0);

  useEffect(async ()=>{
    if(queryParams.id && !called.current){
      called.current =true;
      let splits =  queryParams.id.split('-');
      let id = splits[splits.length-1]-new Date().getFullYear()-splits[0]
      let body ={
        id : id
      }
      await Axios.post("/open/participant",body)
      .then(function (response) {
        if(response.data.status===200){
          setNotification("")
          if(response.data.data.Photo)setPhoto(response.data.data.Photo);
          setFirstname(response.data.data.Firstname);
          setMiddlename(response.data.data.Middlename??'');
          setLastname(response.data.data.Lastname);
          setCourse(response.data.data.Course);
          setSession(response.data.data.Session??'N/A');
          setMobile(response.data.data.Mobile);
          setEmail(response.data.data.Email);
          setIsActive(response.data.data.IsActive)
        }else{
          setNotification("No Active Participant Found !")
        }
      })
      .catch(function (error) {
        setNotification("No Active Participant Found !")
      })
    }

  },[queryParams])

  return (
    <>
      <div className="account-pages pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={6} lg={5} xl={4}>
              <div className="card overflow-hidden">
                <div className='row' style={{paddingTop:"2.5rem"}}>
                  <div className='col-md-12' style={{textAlign:"center"}}>
                      <img src={logo} alt="" height="90"/>
                  </div>
                </div>
                
                <div className="card-body pt-1">
                  <div className="form-horizontal row" style={{fontSize:"15px"}}>
                    {notification && typeof notification === "string" && (
                      <Alert color="danger">{notification}</Alert>
                    )}
                      <div style={{textAlign:"center"}}>
                        <img src={photo} alt={course + "-" + firstname + " "+ middlename+" "+lastname} style={{height:"150px", border:`2px solid #d1c8c8`}} />
                      </div>
                      {/* <div className="col-sm-12" style={{textAlign:"center", fontWeight:"bolder"}}>{isActive==1?"🟢 (Active)" : "🔴 (In-Active)"}</div> */}

                      <div className="col-sm-5 pt-4">Full Name</div>
                      <div className="col-sm-7 pt-4" style={{fontWeight:"bolder"}}>{firstname + " "+ middlename+" "+lastname}</div>

                      {/* <div className="col-sm-5 pt-1">Course</div>
                      <div className="col-sm-7 pt-1" >{course}</div> */}
                      
                      <div className="col-sm-5 pt-1">Session</div>
                      <div className="col-sm-7 pt-1" >{session}</div>
                      
                      <div className="col-sm-5 pt-1">Mobile</div>
                      <div className="col-sm-7 pt-1" >{mobile}</div>
                      
                      <div className="col-sm-5 pt-1">Email</div>
                      <div className="col-sm-7 pt-1" >{email}</div>
                      
                      <div className="col-sm-5 pt-1">Status</div>
                      <div className="col-sm-7 pt-1" >{isActive==1?"🟢 (Active)" : "🔴 (In-Active)"}</div>

                  </div>
                </div>
              </div>
              <div className="mt-5 text-center">
                {/* <p>Don't have an account ? <Link to="/register"
                  className="fw-medium text-primary"> Signup now </Link> </p> */}
                <p>
                  {/* © {new Date().getFullYear()} Sus-Plan <br/> Powered by <a href={Config.applicationInfo.devOrgLink} target="_blank"><span style={{color: "#fd7014"}}>{Config.applicationInfo.devOrgName}</span></a> */}
                  © {new Date().getFullYear()} PGDSCM, BIM <br/> Powered by <a href={Config.applicationInfo.devOrgLink} target="_blank"><span style={{color: "#fd7014"}}>{Config.applicationInfo.devOrgName}</span></a>
                </p>
              </div>
            </Col>
          </Row>

        </Container>
      </div>
    </>
  )
}

export default Participant