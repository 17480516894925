import React, { useState, useEffect, useRef } from "react"
import {
    Row,
    Col,
    Input,
    Card,
    Button as RButton,
    CardBody,
    CardTitle,
    Label,
    CardImg,
    Table,
} from "reactstrap";
import * as helper from '../../utils/helper'
import { Delete, Edit, ChangeCircle, Download } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Typography,
  Tooltip,
} from '@mui/material';
import { AvForm, AvField } from "availity-reactstrap-validation"
import * as CHelper from "../../helpers/custom_helper"
import Axios from "../../helpers/axios_helper"
import * as SHelper from "../../helpers/session_helper"
import { toast,Slide } from 'react-toastify/dist/react-toastify';
import { useHistory } from "react-router-dom"
import * as Session from "../../helpers/session_helper"
import CustomModal from "../Common/CustomModal"

const Model = (props) => {
    const [file, setFile] = useState([]);
    const [files, setFiles] = useState([]);
    const [fileOriginalName, setFileOriginalName] = useState('');

    const selectedItem = useRef(0)
    const selectedItemId = useRef(0)
    const [update_IsActive_modal_center, setUpdate_IsActive_modal_center] = useState(false);
    const [delete_modal_center, setDelete_modal_center] = useState(false);

    const [showCreateNewUI, setShowCreateNewUI] = useState(false);

    const [fileId, setFileId] = useState(-1);
    const [fileIdx, setFileIdx] = useState(-1);
    const [fileName, setFileName] = useState('');
    const [fileSequence, setFileSequence] = useState('1');
    const [participantUser, setParticipantUser] = useState(false);
    const [instructorUser, setInstructorUser] = useState(false);

    const handleSubmit = async (event, errors, values) => {
        window.sLoader();
        const selectedProperties = files.map(({id, fileName, fileSequence, fileNameWithExt}) => ({id, fileName, fileSequence, fileNameWithExt}));

        const formData = new FormData();

        files.forEach(element => {
          formData.append('file', element.file);
        });

        formData.append('body', JSON.stringify(selectedProperties));

        await Axios.post(`/file/uploadWithDB/course&${props.courseId}&${props.moduleId}`, formData, {
        })
        .then(response => {
            toast.success('File Created/Updated successfully!', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
            fetchData();
        })
        .catch(function (error) {
            toast.error("File Uploaded Failed !", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        });

        window.hLoader();
    }

    const addFile = () => {
        if (fileName.length == 0) {
            toast.error(`Name is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        if (!file) {
            toast.error(`File is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        else if (fileSequence == 0) {
            toast.error(`Sequence is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        else {
            const customFile = new File([file], `${fileName}.${file.name.split(".").pop()}`, { type: file.type });

            if (fileIdx > -1) {
                files[fileIdx].id = fileId;
                files[fileIdx].idx = files.length;
                files[fileIdx].fileName = customFile.name;
                files[fileIdx].fileSequence = fileSequence;
                files[fileIdx].fileNameWithExt = customFile.name;
                files[fileIdx].file = customFile;
            }
            else {
                files.push({
                    id : fileId,
                    idx : files.length,
                    fileName : fileName,
                    fileSequence : fileSequence,
                    fileNameWithExt : customFile.name,
                    file : customFile
                });
            }
            setFiles(files);
            cancelFileEdit();
            handleSubmit();
            setShowCreateNewUI(false);
            fetchData();
        }
    }

    const cancelFileEdit = () => {
        setFileId(-1);
        setFileIdx(-1);
        setFileName('');
        // setFileSequence('');
        setFileOriginalName('');
        setFile(null);
        setShowCreateNewUI(true);
        try {
          window.document.getElementById("inputGroupFile02").value = "";
        } catch (error) {}
        
    }

    const editFile = (id, idx) => {
        setFileIdx(idx);
        setFileName(files[idx].fileName);
        // setFileSequence(files[idx].fileSequence);
        setFileOriginalName(files[idx].fileOriginalName);
        setFile(files[idx].file);
    }

    const deleteFile = async (id) => {
        if(id && id>0){
          let filterFile = files.filter(x=>x.id==id)
          if(filterFile.length>0){
            filterFile=filterFile[0];
          }
          const url = `/file/id/${id}`;
          await Axios.post(url,{folderName: filterFile.fileFolderName, folderId: filterFile.fileFolderId, fileNameWithExt:filterFile.fileNameWithExt})
            .then(function (response) {
              if (response && response.data?.status==200) {
                document.getElementById(`fileIsActive${id}`).innerHTML=response.data.fileIsActive==1?"True":"False"
                toast.success(response.data.message, {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 3000,
                  transition: Slide,
                });
                fetchData();
              }
              else {
                toast.error(response.statusText ?? "Data fetching failed !", {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 3000,
                  transition: Slide,
                });
              }
            })
            .catch(function (error) {
              // navigate('/');
            });
        }

        var filterFaq= files.filter(x=>x.idx!=selectedItemId.current);
        setFiles(filterFaq)
    }

    const fileChangeEvent = (event) => {

      const file = event.target.files[0];
      const maxSize = 200 * 1024 * 1024; // 200 MB in bytes
  
      if (file) {
        const allowedTypes = [
          'image/jpeg',
          'image/png',
          'image/gif',
          'application/pdf',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ];
  
        if (!allowedTypes.includes(file.type)) {
          toast.error('Invalid file type. Only images, PDFs, Word, and Excel files are allowed.', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          return;
        }
  
        if (file.size > maxSize) {
          toast.error('File size exceeds 200 MB.', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          return;
        }

        setFile(file);
        setFileOriginalName(file.name)
      }
    }

    const updateIsActiveStatus = async (id) =>{
      const url = `/file/changeFileStatus/${id}`;
      await Axios.patch(url)
        .then(function (response) {
          if (response && response.data?.status==200) {
            document.getElementById(`fileIsActive${id}`).innerHTML=response.data.fileIsActive==1?"True":"False"
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
              transition: Slide,
            });
          }
          else {
            toast.error(response.statusText ?? "Data fetching failed !", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
              transition: Slide,
            });
          }
        })
        .catch(function (error) {
          // navigate('/');
        });
    }

    const modalCallbackDelete = async (result) =>{
      if(result){
        deleteFile(selectedItem.current);
      }
      setUpdate_IsActive_modal_center(false);
      selectedItem.current=0;
      selectedItemId.current=0;
    }
    
    const modalCallbackIsActiveVisibility = async (result) =>{
      if(result){
        updateIsActiveStatus(selectedItem.current);
      }
      setUpdate_IsActive_modal_center(false);
      selectedItem.current=0;
    }

    const fetchData=async()=>{
      window.sLoader();
        setFiles([]);
        await Axios.get(`/file/uploadWithDB/course&${props.courseId}&${props.moduleId}`)
            .then((response) => {
                if (response.data.status == 200 && response.data.data) {
                    setFiles(response.data.data);
                }
            });
        window.hLoader();
    }

    useEffect(async () => {
      const data = Session.getUser()
      if (data && (data.role_code == '444') ) {
        setParticipantUser(true);
      }
      else if (data && (data.role_code == '333') ) {
        setInstructorUser(true);
      }
      if(props.courseId){
        await fetchData();
      }
    }, [props.courseId, props.moduleId])

    return (
        <Row>
          <Col xl="12">
              <Card>
                <CardTitle style={{ fontSize: "18px", textAlign: "center", opacity:"0.7" }}>Materials</CardTitle>
                  <CardBody style={{ paddingTop: "0.5rem" }}>
                      <AvForm className="needs-validation" onSubmit={handleSubmit}>
                      {SHelper.userHasEditPermission() && showCreateNewUI &&
                      <>
                          <Col md="12">
                              <Row>
                                  <Col md="12">
                                      <div className="mb-3">
                                          <Label>Name</Label>
                                          <AvField
                                              name="faqName"
                                              value={fileName}
                                              onChange={(e) => { setFileName(e.target.value); }}
                                              type="text"
                                              className="form-control"
                                          />
                                      </div>
                                  </Col>
                                  {/* <Col md="6">
                                      <div className="mb-3">
                                          <Label>Sequence</Label>
                                          <AvField
                                              name="faqName"
                                              value={fileSequence}
                                              onChange={(e) => { setFileSequence(e.target.value); }}
                                              type="number"
                                              className="form-control"
                                          />
                                      </div>
                                  </Col> */}
                                  <br/>
                                  {fileIdx == -1 &&
                                  <Col md="12">
                                    <div className="mb-3">
                                      {/* <Label>File</Label> */}
                                      <div className="input-group">
                                        <Input type="file" accept=".jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.xls,.xlsx" className="form-control" id="inputGroupFile02" onChange={fileChangeEvent}/>
                                      </div>
                                    </div>
                                  </Col>
                                  }
                              </Row>
                          </Col>
                          <div className="actions clearfix">
                              <RButton
                                  style={{ float: "right", marginLeft: "10px" }}
                                  type="button"
                                  color="primary"
                                  onClick={addFile}
                              >{fileIdx > -1 ? "Update" : "Add"}</RButton>
                              {fileIdx > -1 &&
                                  <RButton
                                      style={{ float: "right" }}
                                      type="button"
                                      color="danger"
                                      onClick={cancelFileEdit}
                                  >Cancel</RButton>}
                                  {showCreateNewUI &&
                                    <div className="actions clearfix">
                                        <RButton
                                            onClick={()=>setShowCreateNewUI(false)}
                                            style={{ float: "left" }}
                                            type="button"
                                            color="danger"
                                            >Cancel</RButton>
                                    </div>}
                          </div>
                          </>}

                          {!showCreateNewUI && SHelper.userHasEditPermission() &&
                            <div className="actions clearfix">
                                <RButton
                                    onClick={()=>setShowCreateNewUI(true)}
                                    style={{ float: "left" }}
                                    type="button"
                                    color="primary"
                                    >Add New File</RButton>
                            </div>}
                            <br/>
                          {/* <CardTitle typeof="h4" >Files List</CardTitle> */}
                          {!showCreateNewUI &&
                          <Col md={12}>
                              <div className="table-responsive">
                                  <Table className="table mb-0">
                                      <thead>
                                          <tr>
                                              <th>#</th>
                                              <th>Date</th>
                                              <th>Name</th>
                                              <th>File</th>
                                              {/* {!participantUser && <th>Publish to All</th>} */}
                                              <th>Action</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          {files.map((item, i) => {
                                              return (
                                                  <tr key={i}>
                                                      <th scope="row">{i + 1}</th>
                                                      <td>{item.createdAt}</td>
                                                      <td>{item.fileName && item.fileName.length > 20 ? `${item.fileName.substring(0, 20)} ...` : item.fileName}</td>
                                                      <td>{item.fileNameWithExt}</td>
                                                      {/* {!participantUser && <td>{item.id && item.id>0 ? <>
                                                        <Typography variant='h7' id={`fileIsActive${item.id}`}>{item.fileIsActive==1?"True":"False"}</Typography>
                                                        <Tooltip arrow placement="left" title="Change">
                                                          <IconButton onClick={() => { selectedItem.current=item.id; setUpdate_IsActive_modal_center(true);}}>
                                                            <ChangeCircle />
                                                          </IconButton>
                                                        </Tooltip></>:<>N/A</>}
                                                      </td>} */}
                                                      <td>
                                                          <Box sx={{ display: fileIdx === i ? 'none' : 'flex' }}>
                                                              {!participantUser &&
                                                              <Tooltip arrow placement="left" title="Delete">
                                                                  <IconButton style={{ color: "red" }} onClick={() => {  selectedItemId.current=i; selectedItem.current=item.id; setDelete_modal_center(true); }}>
                                                                      <Delete />
                                                                  </IconButton>
                                                              </Tooltip>}
                                                              {item.id>0  && <Tooltip arrow placement="left" title="Download">
                                                                  <IconButton onClick={() => { CHelper.downloadFile(item.fileDownloadLink) }}>
                                                                      <Download />
                                                                  </IconButton>
                                                              </Tooltip>}
                                                          </Box>
                                                      </td>
                                                  </tr>
                                              )
                                          })}
                                      </tbody>
                                  </Table>
                              </div>
                          </Col>}
                      </AvForm>
                  </CardBody>
              </Card>
          </Col>

          <CustomModal modelShow={delete_modal_center} handleCallback={modalCallbackDelete}/>
          <CustomModal modelShow={update_IsActive_modal_center} handleCallback={modalCallbackIsActiveVisibility}/>
        </Row>
    )
}

export default Model
