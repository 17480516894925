// src/utils/helpers.js
import moment from 'moment-timezone';
import Moment from 'react-moment';


// Function to format dates using moment-timezone
export const formatDate = (date) => {
    // return moment.tz(date, timezone).format(format);
    // return moment.tz(date, 'YYYY-MM-DD HH:mm:ss', 'Asia/Dhaka').utc().format('DD-MM-YYYY h:mm A');
    return <Moment date={date} format="YYYY-MM-DD h:mm A"/>
};
