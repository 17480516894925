import React, { useState, useEffect, useRef } from "react"
import {
    Label,
    Card,
    CardBody,
    Button,
    Col,
    Form,
    Input,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Row,
    CardTitle,
    CardImg,
    Modal
} from "reactstrap"
import classnames from "classnames"
import Header from "../../components/VerticalLayout/Header"
import Footer from "../../components/VerticalLayout/Footer"
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Select from "react-select"
import CustomModal from "../Common/CustomModal"
import Axios from "../../helpers/axios_helper"
import * as CHelper from "../../helpers/custom_helper"
import { useHistory, Link } from "react-router-dom"
import { toast, Slide } from 'react-toastify/dist/react-toastify';
import mediaImage from "../../assets/images/users/avatar.jpg";
import * as sessionHelper from '../../helpers/session_helper';
import * as Session from "../../helpers/session_helper"
import "./model.scss"

const paymentsModesList = [
    { label: "Bkash", value: "Bkash" },
    { label: "Nagad", value: "Nagad" },
    { label: "Pay Order (Bank)", value: "PayOrderBank)" }
]

const genderList = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Other", value: "Other" }
]

const paymentByList = [
    { label: "MySelf", value: "MySelf" },
    { label: "Organization", value: "Organization" }
]

const statusList = [
    { label: "Approved", value: "1" },
    { label: "Reject", value: "0" }
]

const Model = (props) => { 
    const history = useHistory();
    const [activeTab, setactiveTab] = useState(1);
    const [noPaddingStyle, setNoPaddingStyle] = useState({});
    const [educationDetails, setEducationDetails] = useState([]);//{ id: 0 }
    const [workingDetails, setWorkingDetails] = useState([]);
    const [trainingDetails, setTrainingDetails] = useState([]);
    const [certificationDetails, setCertificationDetails] = useState([]);
    const [addressDetails, setAddressDetails] = useState([]);
    const [updateData, setUpdateData] = useState(false);
    const [academicDateOfBirth, setAcademicDateOfBirth] = useState(false);
    const [sameAsPresentAddress, setSameAsPresentAddress] = useState(false);
    const [imageSrc, setImageSrc] = useState(mediaImage);
    const [paymentFileSrc, setPaymentFileSrc] = useState(false);
    const [files, setFiles] = useState([]);
    const [paymentFiles, setPaymentFiles] = useState([]);
    const [courses, setCourses] = useState([]);
    const [countries, setCountries] = useState([]);
    const [divisions, setDivisions] = useState([]);

    const [presentAddress, setPresentAddress] = useState();
    const [presentVillage, setPresentVillage] = useState();
    const [presentCountryId, setPresentCountryId] = useState(0);
    const [presentDivisionId, setPresentDivisionId] = useState(0);
    const [presentZillas, setPresentZillas] = useState([]);
    const [presentZillaId, setPresentZillaId] = useState(0);
    const [presentUpazillas, setPresentUpazillas] = useState([]);
    const [presentUpazillaId, setPresentUpazillaId] = useState(0);

    const [permanentAddress, setPermanentAddress] = useState();
    const [permanentVillage, setPermanentVillage] = useState();
    const [permanentCountryId, setPermanentCountryId] = useState(0);
    const [permanentDivisionId, setPermanentDivisionId] = useState(0);
    const [permanentZillas, setPermanentZillas] = useState([]);
    const [permanentZillaId, setpermanentZillaId] = useState(0);
    const [permanentUpazillas, setpermanentUpazillas] = useState([]);
    const [permanentUpazillaId, setpermanentUpazillaId] = useState(0);

    const [paymentDetails, setPaymentDetails] = useState([{idx:0}]);
    const [paymentById, setPaymentById] = useState(false);
    const [gender, setGender] = useState(0);

    
    const [userStatus, setUserStatus] = useState('');
    const [ userHasEditPermission, setUserHasEditPermission ] = useState(false);

    const [isParticipant, setIsParticipant]=useState(false);

    const [ urlRequestdata, setUrlRequestdata ] = useState(false);
    const [ requestData, setRequestData ] = useState(false);
    const [ loginBackUrl, setLoginBackUrl ] = useState('/login');
    

    const loadAcademicProfileData = async (id) =>{
        await Axios.get(`/open/academicProfile/details/id/${id??props.id??requestData.academicProfileId}`)
            .then((response) => {
                if (response.data.status === 200) {
                    // setCourseTypeId(response.data.data.courseTypeId);
                    setUpdateData(response.data.data);
                    setAcademicDateOfBirth(response.data.data.academicDateOfBirth)
                    setEducationDetails(response.data.data.educations)
                    setWorkingDetails(response.data.data.experiences)
                    setTrainingDetails(response.data.data.trainings)
                    setCertificationDetails(response.data.data.certifications)
                    if(requestData && requestData.academicProfileId){

                    }
                    else{
                        setPaymentDetails(response.data.data.payments)
                    }
                    setPaymentById(response.data.data.payments[0].paymentBy)
                    setGender(response.data.data.academicGender)
                    setSameAsPresentAddress(response.data.data.academicSameAsPresentAddress)
                    if (response.data.data.addressDetails && response.data.data.addressDetails.length == 0) {
                        let addresses = [];
                        addresses.push({ addressDetailAddressTypeId: 2 });
                        addresses.push({ addressDetailAddressTypeId: 3 });
                        setAddressDetails(addresses);

                    }
                    else {
                        setAddressDetails(response.data.data.addressDetails);

                        let presentAddress = response.data.data.addressDetails[0];
                        setPresentAddress(presentAddress.addressDetailAddress);
                        setPresentVillage(presentAddress.addressDetailVillage);
                        setPresentCountryId(presentAddress.addressDetailCountryId);
                        setPresentDivisionId(presentAddress.addressDetailDivisionId);
                        setPresentZillaId(presentAddress.addressDetailDistrictId);
                        setPresentUpazillaId(presentAddress.addressDetailUpazilaId);

                        let permanentAddress = response.data.data.addressDetails[1];
                        setPermanentAddress(permanentAddress.addressDetailAddress);
                        setPermanentVillage(permanentAddress.addressDetailVillage);
                        setPermanentCountryId(permanentAddress.addressDetailCountryId);
                        setPermanentDivisionId(permanentAddress.addressDetailDivisionId);
                        setpermanentZillaId(permanentAddress.addressDetailDistrictId);
                        setpermanentUpazillaId(permanentAddress.addressDetailUpazilaId);
                    }

                    if (response.data.data.academicProfileFiles && response.data.data.academicProfileFiles.length > 0) {
                        setImageSrc(CHelper.getsimplifyLink(response.data.data.academicProfileFiles[0].link))
                    }
                    if (response.data.data.paymentFiles && response.data.data.paymentFiles.length > 0) {
                        setPaymentFileSrc(response.data.data.paymentFiles[0].link)
                    }
                }
            })
    }

    useEffect(async () => {

        if(props.requestData){
            setUrlRequestdata(props.requestData);
            var urlData = decodeURIComponent(props.requestData);
            urlData = CHelper.decryptData(props.requestData);
            urlData = JSON.parse(urlData);
            // props.id = urlData.academicProfileId;
            if(urlData.academicProfileId>0)
                setRequestData(urlData);
        }

        if(props.loginBackUrl){
            setLoginBackUrl(props.loginBackUrl);
        }
        const user=Session.getUser()
        if(user && user.role_code=='444') {
            setIsParticipant(true)
        }

        await Axios.get("/address/countries")
            .then((response) => {
                if (response.data.status === 200) {
                    setCountries(response.data.data);
                }
            });

        await Axios.get("/address/divisions")
            .then((response) => {
                if (response.data.status === 200) {
                    setDivisions(response.data.data);
                }
            });

        if ((props.id && props.id > 0) || (requestData && requestData.academicProfileId>0) ) {

            await loadAcademicProfileData();

            await Axios.get("/address/districts")
                .then((response) => {
                    if (response.data.status === 200) {
                        setPresentZillas(response.data.data);
                        setPermanentZillas(response.data.data);
                    }
                });

            await Axios.get("/address/upazilas")
                .then((response) => {
                    if (response.data.status === 200) {
                        setPresentUpazillas(response.data.data);
                        setpermanentUpazillas(response.data.data);
                    }
                });
        }

        await Axios.get("/open/course/dropdown")
            .then((response) => {
                if (response.data.status === 200) {
                    setCourses(response.data.data);
                }
            }
        );

            setNoPaddingStyle({
                paddingTop:"0"
            })
        
        if(sessionHelper.userHasEditPermission()){
            setUserHasEditPermission(true);
        }

        return () => {
            // Cancel any outstanding requests or subscriptions here
        };
    }, [props]);

    
    const passwordResetHandler = async () => {
        const values ={
            username:username,
            password:userPassword
        }
        await Axios.post("/auth/login", values)
            .then(function (response) {
            if(response.data.status===200){
                const user = JSON.parse(response.data.details)
                if (user.role_code == '444') {

                    var data = JSON.stringify({
                        academicProfileId : user.user_academicProfileId,
                        token: response.data.token,
                    });
                    
                    data = CHelper.encryptData(data);
                    data = encodeURIComponent(data);
                    window.open(`/participant-registration?status=new&requestData=${data}`)
                    
                }
                else{
                    toast.error('Only participant can enroll the course !', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                }
            }else{
                // setNotification(response.data.message)
            }
        })
        .catch(function (error) {
            // setNotification(error.message)
        })
    }

    const [status_modal_center, setStatus_modal_center] = useState(false);
    const [status_modal_message, setStatus_modal_message] = useState('');
    const [showPasswordEntry, setShowPasswordEntry] = useState(false);
    const [username, setUsername] = useState('');
    const [userPassword, setUserPassword] = useState('');


    return (
        <Row>
            {props.status && <Header applyCustomSettings={true} />}
            {(((!props.id && !props.requestData) || props.id === 0 || (props.id > 0 && updateData) || (props.requestData && updateData))) &&
                <Col xl="12" style={{ margin: "auto" }}>
                    <Card>
                        <CardBody style={noPaddingStyle}>
                            <AvForm className="needs-validation" onSubmit={()=>{}}>
                                <Row>
                                    <Col lg="12">
                                        <Card>
                                            <CardBody style={noPaddingStyle}>
                                                <div className="form-wizard-wrapper wizard clearfix">
                                                    <div className="content clearfix">
                                                        <Row>
                                                            <Col xs={12}>
                                                                <div className="repeater">
                                                                    {paymentDetails && paymentDetails.map((item, idx) => {
                
                                                                        return(
                                                                            <React.Fragment key={idx}>
                                                                            <div data-repeater-list="group-a" id={"repeater" + idx} style={{paddingTop:"20px"}}> 
                                                                                <div data-repeater-item className="row">
                                                                                    <div className="mb-3 col-md-9">
                                                                                        <Label htmlFor={"paymentCourseId[" + idx + "]"}>Program/Course <span className="requiredSign">*</span></Label>
                                                                                        
                                                                                        <Select
                                                                                            name={"paymentCourseId[" + idx + "]"}
                                                                                            id={"paymentCourseId[" + idx + "]"}
                                                                                            options={courses}
                                                                                            isDisabled={true}
                                                                                            value={courses ? courses.filter(x => x.value == item.paymentCourseId)[0] : null}
                                                                                            // onChange={(e) => { setPaymentCourseId(e.value); }}
                                                                                            onChange={(e) => { }}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="mb-3 col-md-3">
                                                                                        <Label htmlFor={"paymentBy[" + idx + "]"}>Payment By</Label>
                                                                            
                                                                                        <Select
                                                                                            name={"paymentBy[" + idx + "]"}
                                                                                            id={"paymentBy[" + idx + "]"}
                                                                                            options={paymentByList}
                                                                                            isDisabled={true}
                                                                                            value={paymentByList.filter(x => x.value == item.paymentBy)}
                                                                                            // onChange={(e) => { setPaymentMode(e.value); }}
                                                                                            onChange={(e) => {}}
                                                                                        />
                                                                                    </div>
                                                                                    {(paymentById==paymentByList[0].value) && <>
                                                                                        <div className="mb-3 col-md-3">
                                                                                            <Label htmlFor={"paymentMode[" + idx + "]"}>Payment Mode</Label>
                                                                                    
                                                                                            <Select
                                                                                                name={"paymentMode[" + idx + "]"}
                                                                                                id={"paymentMode[" + idx + "]"}
                                                                                                options={paymentsModesList}
                                                                                                isDisabled={true}
                                                                                                defaultValue={paymentsModesList.filter(x => x.value == item.paymentMode)}
                                                                                                // onChange={(e) => { setPaymentMode(e.value); }}
                                                                                                onChange={(e) => { }}
                                                                                            />
                                                                                        </div>
                                                                                        <div className="mb-3 col-md-3">
                                                                                            <Label htmlFor={"paymentReference[" + idx + "]"}>Payment Proof (Ref No)</Label>
                                                                                            <AvField
                                                                                                name={"paymentReference[" + idx + "]"}
                                                                                                defaultValue={item.paymentReference}
                                                                                                type="text"
                                                                                                // onChange={(e) => setPaymentReference(e.target.value)}
                                                                                                onChange={(e) => { }}
                                                                                                readOnly={true}
                                                                                                className="form-control"
                                                                                                id={"paymentReference[" + idx + "]"}
                                                                                            />
                                                                                        </div>
                                                                                        {paymentFileSrc &&
                                                                                        <div className="mb-3 col-md-6">
                                                                                            <Label htmlFor="paymentReference">Attach Proof</Label>
                                                                                            <div className="input-group">
                                                                                                {paymentFileSrc && <button type="button" className="btn btn-primary" value="Download" onClick={() => CHelper.downloadFile(paymentFileSrc, "payment")}><i className="fa fa-download"></i> Download Attached File</button>}
                                                                                            </div>
                                                                                        </div>
                                                                                        }
                                                                                    </>}
                                                                                    
                                                                                    {paymentById==paymentByList[1].value && <>
                                                                                        <CardTitle className="pt-4" typeof="h4">Nominated By Information</CardTitle>
                                                                                        <div className="mb-3 col-md-6">
                                                                                            <Label htmlFor={"nominatedByName[" + idx + "]"}>Nominated By</Label>
                                                                                            <AvField
                                                                                                name={"nominatedByName[" + idx + "]"}
                                                                                                defaultValue={item.nominatedByName}
                                                                                                type="text"
                                                                                                onChange={(e) => { }}
                                                                                                readOnly={true}
                                                                                                className="form-control"
                                                                                                id={"nominatedByName[" + idx + "]"}
                                                                                            />
                                                                                        </div>
                                                                                        <div className="mb-3 col-md-6">
                                                                                            <Label htmlFor={"nominatedByDesignation[" + idx + "]"}>Designation</Label>
                                                                                            <AvField
                                                                                                name={"nominatedByDesignation[" + idx + "]"}
                                                                                                defaultValue={item.nominatedByDesignation}
                                                                                                type="text"
                                                                                                onChange={(e) => { }}
                                                                                                readOnly={true}
                                                                                                className="form-control"
                                                                                                id={"nominatedByDesignation[" + idx + "]"}
                                                                                            />
                                                                                        </div>
                                                                                        <div className="mb-3 col-md-6">
                                                                                            <Label htmlFor={"nominatedByOrganization[" + idx + "]"}>Organization</Label>
                                                                                            <AvField
                                                                                                name={"nominatedByOrganization[" + idx + "]"}
                                                                                                defaultValue={item.nominatedByOrganization}
                                                                                                type="text"
                                                                                                onChange={(e) => { }}
                                                                                                isDisabled={true}
                                                                                                className="form-control"
                                                                                                id={"nominatedByOrganization[" + idx + "]"}
                                                                                            />
                                                                                        </div>
                                                                                        <div className="mb-3 col-md-6">
                                                                                            <Label htmlFor={"nominatedByContactNo[" + idx + "]"}>Contact No</Label>
                                                                                            <AvField
                                                                                                name={"nominatedByContactNo[" + idx + "]"}
                                                                                                defaultValue={item.nominatedByContactNo}
                                                                                                type="text"
                                                                                                onChange={(e) => {}}
                                                                                                readOnly={true}
                                                                                                className="form-control"
                                                                                                id={"nominatedByContactNo[" + idx + "]"}
                                                                                            />
                                                                                        </div>
                                                                                    </>}
                                                                                </div>
                                                                            </div>
                                                                        </React.Fragment>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </AvForm>
                        </CardBody>
                    </Card>
                </Col>
            }

            <Modal
            size="sm"
            isOpen={status_modal_center}
            centered={true}>
              
            <div className="modal-header">
              <h5 className="modal-title mt-0">Duplicate profile found !</h5>
              <button
                type="button"
                onClick={() => {
                  setStatus_modal_center(false)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              > 
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{padding:"0"}}>
                <CardBody>
                    <Row>
                        <Col md="12" dangerouslySetInnerHTML={{ __html: status_modal_message }}>
                            {/* {props.bodyMsg?props.bodyMsg:"Are you sure about this action ?"} */}
                        </Col>
                        {showPasswordEntry && <Col md="12" className="pt-1">Password : <input type="password" placeholder="Password" onChange={(e)=>setUserPassword(e.target.value)}/></Col>}
                    </Row>
                </CardBody>
            </div>
            <div className="modal-footer customBtnArea" style={{textAlign: 'right'}}>
                {/* <Button color="primary" type="submit" className="btn btn-sm" onClick={()=>passwordResetHandler(true)}>
                    Yes
                </Button> */}
                {showPasswordEntry && 
                <Button color="primary" type="button" className="btn btn-sm" onClick={passwordResetHandler}>
                    Login
                </Button>}

                <Button color="primary" type="button" className="btn btn-sm" onClick={()=>setStatus_modal_center(false)}>
                    OK
                </Button>
            </div>
          </Modal>
        </Row>
    )
}

export default Model
