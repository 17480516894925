import React, { useState, useEffect } from "react"

import { Link, useLocation, useHistory } from "react-router-dom"
import {
  Row, Col, Input, Label, Card, CardTitle,
  Button,
  Dropdown,
  InputGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import Select from "react-select";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Axios from "../../helpers/axios_helper";
import queryString from 'query-string';

import SideBar from "./sidebar";
import Syllabus from "./syllabus";
import AssesmentSystem from "./assesmentSystem";
import Discussion from "./discussion";
import Quiz from "./quiz";
import Assignment from "./assignment";
import Participant from "./participant";
import Instructor from "./instructor";
import Announcement from "./announcement";
import Class from "./class";
import File from "./file";
import Attendance from "./attendance";
import GradeCenter from "./gradeCenter";
import Rubric from "./rubric";
import * as Session from "../../helpers/session_helper"
import zIndex from "@mui/material/styles/zIndex";

const Course = () => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = queryString.parse(location.search);
  const [courseId, setCourseId] = useState(0);
  const [moduleId, setModuleId] = useState(0);
  const [moduleName, setModuleName] = useState("");
  const [courseModuleId, setCourseModuleId] = useState(0);
  const [courseName, setCourseName] = useState('');
  const [pageName, setPageName] = useState();
  const [courseList, setCourseList] = useState([]);
  const [moduleList, setModuleList] = useState([]);
  const [participantUser, setParticipantUser] = useState(false);
  const [instructorUser, setInstructorUser] = useState(false);
  const [instructorModules, setInstructorModules] = useState([]);

  useEffect(async () => {
    if (queryParams.page) {

      let participantUser = 0;
      let instructorUser = 0;
      const data = Session.getUser()
      if (data && (data.role_code == '444') ) {
        participantUser = 1;
      }
      else if (data && (data.role_code == '333') ) {
        setInstructorUser(true);
        instructorUser = 1;
      }

      setPageName(queryParams.page);
      if (courseList.length == 0) {
        window.sLoader();
        await Axios.get(`/course/dropdown/${instructorUser}&${participantUser}`)
          .then((response) => {
            if (response.data.status === 200) {
              if(instructorUser){
                setCourseList(response.data.data.courses);
                setInstructorModules(response.data.data.modules);
              }else{
                setCourseList(response.data.data);
              }
              if(queryParams.courseId){
                setCourseId(queryParams.courseId);
                const course = response.data.data.filter(x=>x.value==queryParams.courseId)[0]
                if(course){
                  setCourseName(course.label)
                }
                else{
                  history.push('/course-list')
                }
              }
            }
            else {
              setCourseList([])
            }
          });

          if(queryParams.courseId){
            await Axios.get("/course/details/id/"+queryParams.courseId)
            .then((response) => {
              if (response.data.status === 200) {
                const customModules = response.data.data.modules.map(({ courseModuleName, id }) => ({ label: courseModuleName, value: id }));
                setModuleList(customModules);
              }
              else {
                setModuleList([])
              }
            });
          }
        window.hLoader();
      }
    }
  }, [queryParams.page])

  const setSelectedModule = (e) =>{
    setModuleId(e.value); setModuleName(e.label)
  }

  useEffect(async () => {
    window.sLoader();
    const data = Session.getUser()
    if (data && (data.role_code == '333' || data.role_code == '444') ) {
      setParticipantUser(true);
    }

    if (courseId>0 && !instructorUser) {
        setModuleList([])
        setModuleId(0)
        await Axios.get("/course/details/id/"+courseId)
        .then((response) => {
            if (response.data.status === 200) {
            const customModules = response.data.data.modules.map(({ courseModuleName, id }) => ({ label: courseModuleName, value: id }));
            setModuleList(customModules);
            }
        });

    }
    else if (courseId>0 && instructorUser) {
      setModuleId(0);
      const modules = instructorModules.filter(item=>item.courseId==courseId);
      setModuleList(modules);
    }
    else{
      setCourseId(0);
    }
    window.hLoader();
}, [courseId]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title="Course" breadcrumbItem="Details" />
          
        <Row>
          {participantUser &&
          <Col xs="12">
          <Card style={{padding:"2% 4%"}}>
          <Row>
          <Col md="2" sm={12}>
              <div>
                  <div >
                    <div>
                      <div>
                          <Link to="#" className="text-dark fw-medium font-size-16">{Session.getUser().full_name}</Link>
                          <p className="text-body mt-1 mb-0 font-size-13">Active Courses/Trainings: <b>{courseList.length}</b></p>
                      </div>
                    </div>
                  </div>
              </div>
          </Col>
          <Col md="10" sm={12}>
            <Row>
            {(courseId == 0 || participantUser) && 
            <>
            <Col md={6} style={{zIndex:"999"}}>
                <InputGroup>
                    <div
                      className="input-group-append text-center"
                    >
                      <Button type="button" color="primary">
                        Course
                      </Button>
                    </div>
                    <Col md={8} style={{zIndex:"999", width:"75%"}}>
                    <Select
                      options={courseList}
                      value={courseList ? courseList.filter(x => x.value == courseId)[0] : null}
                      onChange={(e) => { setCourseId(e.value); setCourseName(e.label) }}
                      classNamePrefix="select2-selection"
                    />
                  </Col>
                    <div
                      className="input-group-append"
                      onClick={() => {
                      }}
                    >
                    </div>
              </InputGroup>
              </Col>
              <Col md={6}>
              <InputGroup>
              <Col md={4}>
                <span
                   className="btn btn-primary waves-light input-group-append"
                >Subject / Module
                </span>
              </Col>
              <Col md={8} style={{zIndex:"999", width:"62%"}}>
                <Select
                  options={moduleList}
                  value={moduleList.length>0 ? moduleList.filter(x => x.value == moduleId)[0] : null}
                  onChange={(e) => {setModuleId(e.value); setModuleName(e.label) }}
                  classNamePrefix="select2-selection"
                />
              </Col>
              </InputGroup>
              </Col>
              </>}
            </Row>
          </Col>
            
              </Row>
              </Card>
        </Col>}

          { (!participantUser || (participantUser && courseId>0 && moduleId>0)) &&
          <Col xs="12">
            <SideBar pageName={pageName} />

            <div className="email-rightbar mb-3">
              <Card>
                
                {!participantUser &&
                  <>
                    {/* <Col md={3}>
                      <span
                        className="btn btn-primary waves-light"
                      >Select a Course
                      </span>
                    </Col>
                    <Col md={3}>
                      <Select
                        options={courseList}
                        value={courseList ? courseList.filter(x => x.value == courseId)[0] : null}
                        onChange={(e) => { setCourseId(e.value); setCourseName(e.label) }}
                        classNamePrefix="select2-selection"
                      />
                    </Col> */}
                    <InputGroup className="p-4">
                        <div
                          className="input-group-append text-center"
                        >
                          <Button type="button" color="primary">
                          Select a Subject / Module
                          </Button>
                        </div>
                        <Col md={8} style={{zIndex:"999", width:"72%"}}>
                        <Select
                          options={moduleList}
                          value={moduleList ? moduleList.filter(x => x.value == moduleId)[0] : null}
                          onChange={(e) => setSelectedModule(e)}
                          classNamePrefix="select2-selection"
                        />
                      </Col>
                        <div
                          className="input-group-append"
                          onClick={() => {
                          }}
                        >
                        </div>
                  </InputGroup>
                    
                </>
                }

                {courseId > 0 &&
                  <Col md={12} className="pt-4">
                    <CardTitle style={{ fontSize: "20px", textAlign: "center" }}>{courseName} {moduleName && pageName != 'grade-center' && pageName != 'rubric' && <span  style={{fontWeight:"bold", color:"#2f4ab9"}}>- {moduleName}</span>}</CardTitle>
                  </Col>
                }
                {pageName == 'announcement' &&
                  <Announcement courseId={courseId} moduleId={moduleId}/>
                }
                {pageName == 'syllabus' &&
                  <Syllabus courseId={courseId} moduleId={moduleId}/>
                }
                {pageName == 'assesment-system' &&
                  <AssesmentSystem courseId={courseId} moduleId={moduleId}/>
                }
                {pageName == 'assignments' &&
                  <Assignment courseId={courseId} moduleId={moduleId}/>
                }
                {pageName == 'participants' &&
                  <Participant courseId={courseId} moduleId={moduleId}/>
                }
                {pageName == 'instructors' &&
                  <Instructor courseId={courseId} moduleId={moduleId}/>
                }
                {pageName == 'classes' &&
                  <Class courseId={courseId} moduleId={moduleId}/>
                }
                {pageName == 'files' &&
                  <File courseId={courseId} moduleId={moduleId}/>
                }
                {pageName == 'attendances' &&
                  <Attendance courseId={courseId} moduleId={moduleId}/>
                }
                {pageName == 'discussion' &&
                  <Discussion courseId={courseId} moduleId={moduleId}/>
                }
                {pageName == 'course-quiz' &&
                  <Quiz courseId={courseId} moduleId={moduleId}/>
                }
                {pageName == 'rubric' &&
                  <Rubric courseId={courseId} moduleId={moduleId}/>
                }
                {pageName == 'grade-center' &&
                  <GradeCenter courseId={courseId} moduleId={moduleId}/>
                }
              </Card>
            </div>
          </Col>}
        </Row>
      </div>
    </React.Fragment>
  )
}

export default Course