import React, { useMemo, useEffect, useState, useRef } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button,
    Input,
    Label,
    Modal,
    Table,
} from "reactstrap";
import * as helper from '../../utils/helper'
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import QuizSubmission from "./quizSubmission"
import Axios from "../../helpers/axios_helper"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { toast,Slide } from 'react-toastify/dist/react-toastify';
import MaterialReactTable from 'material-react-table';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Select from "react-select"

import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, ContentState } from "draft-js"; 
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from 'draft-js-import-html';
import { List,Delete,Edit,PlayArrow,Grading, Mail } from '@mui/icons-material';
import {
  Box,
//   Button,
  IconButton,
  Typography,
  Tooltip,
} from '@mui/material';
import * as SHelper from "../../helpers/session_helper"
import AcademicProfileModal from "../AcademicProfile/model"
import CustomModal from "../Common/CustomModal"

const options = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
  ];

const DatatableTables = (props) => {
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const selectedItem = useRef(0)
  const [modal_center, setmodal_center] = useState(false)
  const [update_status_modal_center, setUpdate_status_modal_center] = useState(false);
  const [announcement_mail_modal, setAnnouncement_mail_modal] = useState(false);

  const [columnFilters, setColumnFilters] = useState([]);
  const [columnFilterFns, setColumnFilterFns] = useState({
    numberOfQuestions: 'contains',
    title: 'contains',
    questionSet: 'contains',
    published: 'equal'
  });
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const tableInstanceRef = useRef(null);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5
  });

    const [quizSubmissions, setQuizSubmissions] = useState([]);
    const [showCourseQuizSubmissions, setShowCourseQuizSubmissions] = useState(false);
    const [startQuiz, setStartQuiz] = useState(false);
    const [courseModuleId, setCourseModuleId] = useState(false);
    const [courseCourseId, setCourseCourseId] = useState(false);
    const [showCreateNewUI, setShowCreateNewUI] = useState(false);
    const [courseQuizId, setCourseQuizId] = useState(null);
    const [courseQuizIdx, setCourseQuizIdx] = useState(-1);
    const [courseQuizTitle, setCourseQuizTitle] = useState('');
    const [courseQuizSetName, setCourseQuizSetName] = useState('');
    const [courseQuizNoOfQuestions, setCourseQuizNoOfQuestions] = useState('');
    const [courseQuizStartDate, setCourseQuizStartDate] = useState(null);
    const [courseQuizEndDate, setCourseQuizEndDate] = useState(null);
    const [courseQuizPoints, setCourseQuizPoints] = useState(null);
    const [courseQuizPublished, setCourseQuizPublished] = useState(null);    
    const [overviewState, setOverviewState] = useState(EditorState.createEmpty());
    const [overviewContent, setOverviewContent] = useState();
    const [courseSetNamesList, setCourseSetNamesList] = useState();


    const handleOverviewChange = (newEditorState) => {
        setOverviewState(newEditorState);
        setOverviewContent(stateToHTML(newEditorState.getCurrentContent()));
    };

    const startQuizHandler = (id, setName)=>{
        setStartQuiz({
            quizId:id,
            setName:setName
        });
    };

    const seeQuizResultHandler = (id, setName)=>{
      setStartQuiz({
          quizId:id,
          setName:setName,
          showResultOnly:true
      });
    };

    const sendAnnouncementMail = async (modalResponse) => {
      if(!modalResponse) return;
      await Axios.post(`/course-quiz/sendMail/${selectedItem.current}`)
      .then((response) => {
          if (response.data.status==200) {
              toast.success(`Mail Send successfully !`, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
              });
          }
          else {
              toast.error(`Mail Send failed !`, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
              });
          }
      });
      setAnnouncement_mail_modal(false);
  }

    const handleSubmit = async (event, errors, values) => {
        window.sLoader();
        if (!props.courseId) {
            toast.error(`Course is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        else if (!props.moduleId) {
            toast.error(`Module is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        else {

            const jsonData = {
                title: courseQuizTitle,
                instructions: overviewContent,
                numberOfQuestions: courseQuizNoOfQuestions,
                point: courseQuizPoints,
                questionSet: courseQuizSetName,
                courseId: props.courseId,
                moduleId: props.moduleId,
                startDatetime: courseQuizStartDate,
                endDatetime: new Date(courseQuizEndDate),
                published: courseQuizPublished,
            }
            const url = courseQuizId?`/course-quiz/${courseQuizId}`:`/course-quiz`;
            await Axios.post(url, jsonData)
                .then(async (response) => {
                    if (response.data.status === 200) {
                        toast.success('Course Quiz Created/Updated successfully!', {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                        fetchDataFromAPI(true);
                        cancelcourseQuizEdit();
                        showCreateNewUI(false);
                    } else {
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    }
                    cancelcourseQuizEdit();
                })
                .catch((e) => {
                    toast.error(e, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                })

        }
        window.hLoader();
    }

    const addcourseQuiz = () => {
        handleSubmit();
    }

    const editcourseQuiz = async (id) => {
        await Axios.get("/course-quiz/"+id)
        .then((response) => {
            if (response.data.status==200) {
                setCourseQuizId(response.data.data.id);
                // setCourseQuizIdx(idx);
                setCourseModuleId(response.data.data.moduleId);
                setCourseCourseId(response.data.data.courseId);
                setCourseQuizTitle(response.data.data.title);
                setCourseQuizPoints(response.data.data.point);
                setCourseQuizPublished(response.data.data.published);
                setCourseQuizNoOfQuestions(response.data.data.numberOfQuestions);
                setCourseQuizSetName(response.data.data.questionSet);
                const courseQuizInstruction = response.data.data.instructions;
                setOverviewContent(courseQuizInstruction)
                const courseOverview = EditorState.createWithContent(stateFromHTML(courseQuizInstruction));
                setOverviewState(courseOverview);
                setCourseQuizStartDate(new Date(response.data.data.startDatetime));
                setCourseQuizEndDate(new Date(response.data.data.endDatetime));

                setShowCreateNewUI(true);
            }
        });
        
    }

    const cancelcourseQuizEdit = () => {
        setCourseQuizIdx(0);
        setCourseQuizId(null);
        setCourseQuizTitle('');
        setCourseQuizPoints('')
        setCourseQuizNoOfQuestions('');
        setOverviewState(EditorState.createEmpty());
        setOverviewContent('');
        setCourseQuizStartDate(null);
        setCourseQuizEndDate(null);
        setCourseQuizStartDate(null);
        setCourseQuizEndDate(null);
        setShowCreateNewUI(false)
        setCourseCourseId(null);
        setCourseModuleId(null);
        setStartQuiz(false)
    }

  //#region Datatable

  const columns = useMemo(
    () => {
      const iColums = [
      {
        size:50,
        accessorKey: 'sl',
        header: "#SL",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{cell.getValue()}</Typography>
        ),
        enableColumnFilterModes: false,
        enableColumnActions:false,
        enableSorting: false
      },
      {
        accessorKey: 'title',
        header: "Quiz Title",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{cell.getValue()}</Typography>
        ),
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      },
      {
        accessorKey: 'startDatetime',
        header: "Start",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{row.original.startDatetime}</Typography>
        ),
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      },
      {
        accessorKey: 'endDatetime',
        header: 'End',
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{row.original.endDatetime}</Typography>
        ),
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      },
      {
        accessorKey: 'numberOfQuestions',
        header: "No. Of Ques",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{cell.getValue()}</Typography>
        ),
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      },
      {
        accessorKey: 'status',
        header: "Status",
        Cell: ({ cell, row }) => (
          // <Typography variant='h7'>{row.original.startDatetime}</Typography>
          <span className={checkDateRange(row.original.startDatetime,row.original.endDatetime)[0]}>{checkDateRange(row.original.startDatetime,row.original.endDatetime)[1]}</span>
        ),
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      },
    ]
    if(SHelper.userHasEditPermission()){
      iColums.push(
        {
          accessorKey: 'questionSet',
          header: "Question Set",
          Cell: ({ cell, row }) => (
            <Typography variant='h7'>{cell.getValue()}</Typography>
          ),
          enableColumnFilterModes: true,
          filterFn: 'contains',
          columnFilterModeOptions: ['contains'],
        },
        {
          accessorKey: 'published',
          header: "Published",
          Cell: ({ cell, row }) => (
            <Typography variant='h7'>{cell.getValue()==1?"Yes":"No"}</Typography>
          ),
          enableColumnFilterModes: true,
          filterFn: 'contains',
          columnFilterModeOptions: ['contains'],
        });
    }
    // else{
    //   columns.push({
    //     accessorKey: 'category',
    //     header: "Category",
    //     Cell: ({ cell, row }) => (
    //       <Typography variant='h7'>{cell.getValue()}</Typography>
    //     ),
    //     enableColumnFilterModes: true,
    //     filterFn: 'contains',
    //     columnFilterModeOptions: ['contains'],
    //   });
    // }
    return iColums;
  },
    [],
  );

  const findFilterCondition = (name) => {
    var condition = '';
    if (columnFilterFns[name]) {
      condition = columnFilterFns[name];
    }
    return condition;
  }

  function checkDateRange(fromDate, toDate) {
    const currentDate = new Date();
    const startDate = new Date(fromDate);
    const endDate = new Date(toDate);

    if (currentDate >= startDate && currentDate <= endDate) {
        return ["badge badge-soft-success font-size-12","Ongoing",true]; //'Current date is within the range'
    } else if (currentDate > startDate) {
        return ["badge badge-soft-danger font-size-12","Completed",false]; //'Current date is before the range'
    } else {
        return ["badge badge-soft-warning font-size-12","Upcoming", false]; //'Current date is after the range'
    }
  }

  const fetchDataFromAPI = async (reset = false) => {
    
    window.sLoader();
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    let filterQuery = '';
    let sorterQuery = '';

    if (!reset) {
      if (columnFilters.length > 0) {
        columnFilters.forEach(element => {
          if (element.id === 'invoicedate' || element.id === 'duedate') {
            // filterQuery += `&filter=${element.id},${findFilterCondition(element.id)},${AuthVerify.getDate(element.value)}`;
          }
          else {
            filterQuery += `&filter=${element.id},${findFilterCondition(element.id)},${element.value}`;
          }
        });
      }
      if (sorting.length > 0) {
        sorting.forEach(element => {
          sorterQuery += `&sortby=${element.id}&orderby=${element.desc ? 'desc' : 'asc'}`;
        });
      }
    } else {
      setPagination({
        pageIndex: 0,
        pageSize: 5
      });
      tableInstanceRef.current.resetRowSelection();
    }

    const url = `/course-quiz/list/${props.courseId}&${props.moduleId}?limit=${pagination.pageSize}&offset=${pagination.pageIndex * pagination.pageSize}${sorterQuery}${filterQuery}`;
    // let url = `/page/list`;
    await Axios.get(url)
      .then(function (response) {
        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
        if (response && response.data?.data) {
          setData(response.data?.data);
          setRowCount(response.data.recordsTotal);
        }
        else {
          toast.error(response.statusText ?? "Data fetching failed !", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            transition: Slide,
          });
        }
      })
      .catch(function (error) {
        // navigate('/');
      });
      window.hLoader();
  };

  useEffect(() => {
    if(props.courseId && props.moduleId && !showCreateNewUI);
    setCourseCourseId(props.courseId);
    setCourseModuleId(props.moduleId);
    fetchDataFromAPI();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    props.courseId,
    props.moduleId,
    showCreateNewUI
  ]);

  useEffect(async () => {
    await Axios.get(`/question-bank/unique-set-names`)
    .then((response) => {
    if (response.data.status === 200) {
        setCourseSetNamesList(response.data.data);
    }
    else {
        setCourseSetNamesList([])
    }
    })
    .catch((error)=>{
        toast.error(error.message ?? "Data fetching failed !", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            transition: Slide,
          });
    })
  }, []);
  //#endregion

  //#region Modal

  const handleCallback = (response) =>{
    selectedItem.current=0;
    setmodal_center(false);
    // loadList();
  }

  const modalCallbackStatus = async (result) =>{
    if(result){
      updateActiveStatus(selectedItem.current);
    }
    setUpdate_status_modal_center(false);
    selectedItem.current=0;
  }

  const updateActiveStatus = async (id) =>{
    window.sLoader();
    const url = `/course-quiz/${id}`;
    await Axios.delete(url)
      .then(function (response) {
        if (response && response.data?.status==200) {
          // document.getElementById(`academicIsActive${id}`).innerHTML=response.data.academicIsActive==1?"True":"False"
          fetchDataFromAPI(true);
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            transition: Slide,
          });
        }
        else {
          toast.error(response.statusText ?? "Data fetching failed !", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            transition: Slide,
          });
        }
      })
      .catch(function (error) {
        // navigate('/');
      });
      window.hLoader();
  }

  const courseQuizSubmissions = async (id) => {
    await Axios.get(`/quiz/submissions/${id}`)
    .then((response) => {
        if (response.data.status==200) {
            setQuizSubmissions(response.data.data);
            setShowCourseQuizSubmissions(true);
        }
        else{

        }
    });
    // fetchSubmittedFiles(id);
}

  //#endregion
  
  return (
    <>
        <Row>
          <Col className="col-12">
            <Card>
            {(!props.id || props.id === 0) &&
                <Col xl="12">
                    <Card>
                        <CardTitle style={{ fontSize: "18px", textAlign: "center", opacity:"0.7" }}>Quiz</CardTitle>
                        <CardBody style={{ paddingTop: "0.5rem" }}>
                            <AvForm className="needs-validation" onSubmit={handleSubmit}>
                            {SHelper.userHasEditPermission() && showCreateNewUI &&
                            <>
                                <Col md="12">
                                    <Row>
                                        <Col md="12">
                                            <div className="mb-3">
                                                <Label>Title</Label>
                                                <AvField
                                                    name="courseQuizTitle"
                                                    value={courseQuizTitle}
                                                    onChange={(e) => { setCourseQuizTitle(e.target.value); }}
                                                    type="text"
                                                    className="form-control"
                                                />
                                            </div>
                                        </Col>
                                       <Col md="6">
                                            <div className="mb-3">
                                                <Label>Number of Questions</Label>
                                                <AvField
                                                    name="courseQuizNoOfQuestions"
                                                    value={courseQuizNoOfQuestions}
                                                    onChange={(e) => { setCourseQuizNoOfQuestions(e.target.value); }}
                                                    type="number"
                                                    className="form-control"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Label>Question Set</Label>
                                                {/* <AvField
                                                    name="courseQuizSetName"
                                                    value={courseQuizSetName}
                                                    onChange={(e) => { setCourseQuizSetName(e.target.value); }}
                                                    type="text"
                                                    className="form-control"
                                                /> */}
                                                <Select
                                                options={courseSetNamesList}
                                                value={courseSetNamesList ? courseSetNamesList.filter(x => x.value == courseQuizSetName)[0] : null}
                                                onChange={(e) => { setCourseQuizSetName(e.value); }}
                                                classNamePrefix="select2-selection"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Label>Start</Label>
                                                <Flatpickr
                                                    name={"courseQuizStartDate"}
                                                    className="form-control d-block"
                                                    // placeholder="dd M, yyyy"
                                                    value={courseQuizStartDate}
                                                    options={{
                                                        dateFormat: 'Y-m-d h:i K',
                                                        timeFormat: 'h:i K',
                                                        enableTime: true,
                                                        time_24hr: false,
                                                        // altInput: true,
                                                        // altFormat: 'F j, Y h:i K',
                                                        // altInputClass: 'flatpickr-alt-input',
                                                    }}
                                                    onChange={(selectedDates, dateStr, instance) => {
                                                        setCourseQuizStartDate(dateStr);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Label>End</Label>
                                                <Flatpickr
                                                    name={"courseQuizEndDate"}
                                                    className="form-control d-block"
                                                    // placeholder="dd M, yyyy"
                                                    value = {courseQuizEndDate}
                                                    options={{
                                                        dateFormat: 'Y-m-d h:i K',
                                                        timeFormat: 'h:i K',
                                                        enableTime: true,
                                                        time_24hr: false,
                                                    }}
                                                    onChange={(selectedDates, dateStr, instance) => {
                                                        setCourseQuizEndDate(dateStr);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Label>Points</Label>
                                                <AvField
                                                    name="courseQuizPoints"
                                                    value={courseQuizPoints}
                                                    onChange={(e) => { setCourseQuizPoints(e.target.value); }}
                                                    type="number"
                                                    className="form-control"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Label>Published</Label>
                                                <Select
                                                    value={options.find(option => option.value === courseQuizPublished)}
                                                    isMulti={false}
                                                    onChange={(data) => {
                                                        setCourseQuizPublished(data.value);
                                                    }}
                                                    options={options}
                                                    classNamePrefix="select2-selection"
                                                    />
                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="mb-3">
                                                <Label>Instructions</Label>
                                                <Editor
                                                    editorState={overviewState}
                                                    onEditorStateChange={handleOverviewChange}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <div className="actions clearfix">
                                    <Button
                                        style={{ float: "right", marginLeft: "10px" }}
                                        type="button"
                                        color="primary"
                                        onClick={addcourseQuiz}
                                    >{courseQuizId? "Update" : "Add"}</Button>
                                    {courseQuizId &&
                                        <Button
                                            style={{ float: "right" }}
                                            type="button"
                                            color="danger"
                                            onClick={cancelcourseQuizEdit}
                                        >Cancel Edit</Button>}
                                    {showCreateNewUI && !courseQuizId &&
                                        <div className="actions clearfix">
                                            <Button
                                                onClick={()=>{setShowCreateNewUI(false); cancelcourseQuizEdit();}}
                                                style={{ float: "left" }}
                                                type="button"
                                                color="danger"
                                                >Cancel</Button>
                                        </div>}
                                </div>
                                </>}
                                {SHelper.userHasEditPermission() && !showCreateNewUI &&
                                    <div className="actions clearfix">
                                        <Button
                                            onClick={()=>setShowCreateNewUI(true)}
                                            style={{ float: "left" }}
                                            type="button"
                                            color="primary"
                                            >Create New Course Quiz</Button>
                                    </div>}
                            </AvForm>

                            {!showCreateNewUI &&
                            <MaterialReactTable
                                columns={columns}
                                data={data}
                                enableColumnActions={false}
                                enableGlobalFilter={false}
                                enableColumnFilterModes
                                initialState={{
                                showColumnFilters: false
                                }}
                                manualFiltering
                                manualPagination
                                manualSorting
                                onColumnFilterFnsChange={setColumnFilterFns}
                                onColumnFiltersChange={setColumnFilters}
                                onGlobalFilterChange={setGlobalFilter}
                                onPaginationChange={setPagination}
                                onSortingChange={setSorting}
                                tableInstanceRef={tableInstanceRef}
                                rowCount={rowCount}
                                enableEditing
                                state={{
                                columnFilters,
                                columnFilterFns,
                                globalFilter,
                                isLoading,
                                pagination,
                                showAlertBanner: isError,
                                showProgressBars: isRefetching,
                                sorting,
                                }}
                                positionActionsColumn="last"
                                displayColumnDefOptions={{
                                'mrt-row-actions': {
                                    header: 'Action',
                                },
                                }}

                                renderRowActions={({ row, table }) => (
                                <Box sx={{ display: 'flex' }}>
                                    {!SHelper.userHasEditPermission() ? 
                                    <>
                                    {checkDateRange(row.original.startDatetime,row.original.endDatetime)[2] ?
                                    <Tooltip arrow placement="left" title="Start Quiz">
                                    <IconButton onClick={() => {startQuizHandler(row.original.id,row.original['questionSet']);}}>
                                        <PlayArrow />
                                    </IconButton>
                                    </Tooltip>:
                                    <Tooltip arrow placement="left" title="See Quiz Result">
                                    <IconButton onClick={() => {seeQuizResultHandler(row.original.id,row.original['questionSet']);}}>
                                        <Grading />
                                    </IconButton>
                                    </Tooltip>}
                                    </>:
                                    <>
                                    <Tooltip arrow placement="left" title="Edit">
                                    <IconButton onClick={() => {editcourseQuiz(row.original.id);}}>
                                        <Edit />
                                    </IconButton>
                                    </Tooltip>
                                    <Tooltip arrow placement="top" title="Send Mail to All">
                                      <IconButton onClick={() => { selectedItem.current=row.original.id; setAnnouncement_mail_modal(true) }}>
                                          <Mail />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip arrow placement="left" title="Show Submissions">
                                    <IconButton style={{ color: "blue" }}  onClick={() => {courseQuizSubmissions(row.original.id)}}>
                                        <Grading />
                                    </IconButton>
                                    </Tooltip>
                                    <Tooltip arrow placement="left" title="Show Submissions">
                                    <IconButton style={{ color: "red" }} onClick={() => {setUpdate_status_modal_center(true); selectedItem.current=row.original.id;}}>
                                        <Delete />
                                    </IconButton>
                                    </Tooltip>
                                    </>}
                                </Box>
                                )}
                            />
                        }
                        </CardBody>
                    </Card>
                </Col>
            }
            </Card>
          </Col>

          <Modal
            size="xl"
            isOpen={showCourseQuizSubmissions}
            centered={true}>

            <div className="modal-header">
              <h5 className="modal-title mt-0">Quiz Submission</h5>
              <button
                type="button"
                onClick={()=>{setShowCourseQuizSubmissions(false)}}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              > 
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
                <div className="table-responsive">
                        <Table className="table-centered">
                            <thead>
                                <tr>
                                    <th scope="col">Submission Date</th>
                                    <th scope="col">Student name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Contact</th>
                                    <th scope="col">Score</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {quizSubmissions.map((item, i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{item.submissionDate}</td>
                                            <td>{item.academicProfile.academicFirstName} {item.academicProfile.academicLastName}</td>
                                            <td>{item.academicProfile.academicEmail}</td>
                                            <td>{item.academicProfile.academicContactNo}</td>
                                            <td>{item.score}</td>
                                            <td>
                                            <Box sx={{ display: 'flex' }}>
                                              <Tooltip arrow placement="left" title="See Details">
                                              <IconButton onClick={() => {seeQuizResultHandler(item.id,item['questionSet']);}}>
                                                  <List />
                                              </IconButton>
                                              </Tooltip>:
                                          </Box>
                                            </td>
                                        </tr>
                                    )
                                })}
                                
                            </tbody>
                        </Table>
                    </div>
            </div>
          </Modal>

          <CustomModal modelShow={update_status_modal_center} handleCallback={modalCallbackStatus} bodyMsg={"Do you want to delete this data ?"}/>
          <CustomModal modelShow={announcement_mail_modal} handleCallback={sendAnnouncementMail} bodyMsg={"Would you like to send this quiz email to all of your students?"}/>
          {/* <CustomModal modelShow={reset_modal_center} handleCallback={modalCallbackReset} bodyMsg={"New password will sent to the user through user email address."}/> */}
        </Row>
        <QuizSubmission startQuiz={startQuiz} handleCallback={cancelcourseQuizEdit}/>
    </>
  )
}

export default DatatableTables
