import React, { useState, useEffect } from "react"
import {
  TabContent,
  TabPane,
  NavLink,
  Nav,
  Card,
  Row,
  Col,
  CardBody,
  CardTitle,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import classnames from "classnames"
import Axios from "../../../helpers/axios_helper"
import * as Session from "../../../helpers/session_helper"
import Profile from "./profile"
import Account from "./account"
import Academic from "./academic"
import Payment from "./payment"
import Instructor from "./instructor"

const FormValidations = () => {

  const [text, setText] = useState("Profile");
  const [userDetailsAll, setUserDetailsAll] = useState(false);
  const [adminUser, setAdminUser] = useState(false);
  const [academicUser, setAcademicUser] = useState(false);
  const [instructorUser, setInstructorUser] = useState(false);
  const [participantUser, setParticipantUser] = useState(false);
  const [userUser, setUserUser] = useState(false);
  const [alumniUser, setAlumniUser] = useState(false);
  const [other, setOther] = useState(false);

  const [activeTabV, setactiveTabV] = useState("15")

  function toggleV(tab) {
    if (activeTabV !== tab) {
      setactiveTabV(tab)
    }
  }

  useEffect(async () => {
    
    window.sLoader();
    
    const data = Session.getUser()
    if (data && data.role_code == '111') {
      setAdminUser(true)
      setText("Admin")
    }
    else if (data && data.role_code == '222') {
      setAcademicUser(true)
      setText("Academic")
    }
    else if (data && data.role_code == '333') {
      setInstructorUser(true)
      setText("Instructor")
    }
    else if (data && data.role_code == '444') {
      setParticipantUser(true)
      setText("Participant")
    }
    else if (data && data.role_code == '555') {
      setAlumniUser(true)
      setText("Alumni")
    }
    else if (data && data.role_code == '666') {
      setUserUser(true)
      setText("Other")
    }
        
    await Axios.get(`/user/details/id/${data.user_id}`)
    .then((response) => { 
    if(response.data.status===200){
        setUserDetailsAll(response.data.data);
        // setUpdateData(response.data.data);
        // if(response.data.data.files.length>0){
        //     setImageSrc(CHelper.getsimplifyLink(response.data.data.files[0].link))
        // }
    }
    })
    window.hLoader();
}, []);

  return (
    <>
      <div className="page-content">
        <Breadcrumbs title="User Profile" breadcrumbItem={text} />
        {/* { adminUser && <AdminProfile/>}
          { academicUser && <AcademicProfile/>}
          { participantUser && <ParticipantProfile/>}
          { userUser && <UserProfile/>}
          { alumniUser && <AlumniProfile/>} */}

        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>

                <Row>
                  <Col md={2}>
                    <Nav pills className="flex-column">

                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames('mb-2', {
                          active: activeTabV === "15",
                        })}
                        onClick={() => {
                          toggleV("15")
                        }}
                      >
                        <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        <span className="d-none d-sm-block">Profile</span>
                      </NavLink>

                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames('mb-2', {
                          active: activeTabV === "16",
                        })}
                        onClick={() => {
                          toggleV("16")
                        }}
                      >
                        <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                        <span className="d-none d-sm-block">Account</span>
                      </NavLink>

                      {(academicUser || participantUser) && 
                      <>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames('mb-2', {
                          active: activeTabV === "17",
                        })}
                        onClick={() => {
                          toggleV("17")
                        }}
                      >
                        <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                        <span className="d-none d-sm-block">Academic</span>
                      </NavLink>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames('mb-2', {
                          active: activeTabV === "19",
                        })}
                        onClick={() => {
                          toggleV("19")
                        }}
                      >
                        <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                        <span className="d-none d-sm-block">Payment History</span>
                      </NavLink>
                      </>}

                      {instructorUser && <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames('mb-2', {
                          active: activeTabV === "18",
                        })}
                        onClick={() => {
                          toggleV("18")
                        }}
                      >
                        <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                        <span className="d-none d-sm-block">Instructor</span>
                      </NavLink>}

                    </Nav>
                  </Col>
                  <Col md={10}>
                    <TabContent activeTab={activeTabV} className="text-muted mt-4 mt-md-0">
                      <TabPane tabId="15">
                        <Profile userDetailsAll={userDetailsAll}/>
                      </TabPane>
                      <TabPane tabId="16">
                        <Account userDetailsAll={userDetailsAll}/>
                      </TabPane>
                      <TabPane tabId="17">
                        <Academic userDetailsAll={userDetailsAll}/>
                      </TabPane>
                      <TabPane tabId="18">
                        <Instructor userDetailsAll={userDetailsAll}/>
                      </TabPane>
                      <TabPane tabId="19">
                        <Payment userDetailsAll={userDetailsAll}/>
                      </TabPane>
                    </TabContent>
                  </Col>
                </Row>

              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default FormValidations
