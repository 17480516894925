import React, { useState, useEffect, useRef } from "react"
import {
    Label,
    Card,
    CardBody,
    Button,
    Col,
    Form,
    Input,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Row,
    CardTitle,
    CardImg,
    Modal
} from "reactstrap"
import classnames from "classnames"
import Header from "../../components/VerticalLayout/Header"
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Flatepicker
import Axios from "../../helpers/axios_helper"
import * as CHelper from "../../helpers/custom_helper"
import { useHistory, Link } from "react-router-dom"
import { toast, Slide } from 'react-toastify/dist/react-toastify';
import mediaImage from "../../assets/images/placeholder.svg";
import * as sessionHelper from '../../helpers/session_helper';
import * as Session from "../../helpers/session_helper"
import "./model.scss"

const Model = (props) => { 
    const history = useHistory();
    const [activeTab, setactiveTab] = useState(1);
    const [noPaddingStyle, setNoPaddingStyle] = useState({});
    const [updateData, setUpdateData] = useState(false);
    
    const [eventImageSrc, setEventImageSrc] = useState(mediaImage);
    const [eventImage, setEventImage] = useState([]);
    const [studyImageSrc, setStudyImageSrc] = useState(mediaImage);
    const [studyImage, setStudyImage] = useState([]);
    const [researchImageSrc, setResearchImageSrc] = useState(mediaImage);
    const [researchImage, setResearchImage] = useState([]);
    const [aboutImageSrc, setAboutImageSrc] = useState(mediaImage);
    const [aboutImage, setAboutImage] = useState([]);
    const [storyImageSrc, setstoryImageSrc] = useState(mediaImage);
    const [storyImage, setStoryImage] = useState([]);
    const [newsBlogSrc, setNewsBlogImageSrc] = useState(mediaImage);
    const [newsBlogImage, setNewsBlogImage] = useState([]);
    const [footerImageSrc, setFooterImageSrc] = useState(mediaImage);
    const [footerImage, setFooterImage] = useState([]);

    function toggleTab(tab) {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= 7) {
                setactiveTab(tab)
            }
        }
    }

    const handleSubmit = async (data) => {
        if(!data)return false;
        let errorFound = false;
        let formData = new FormData();
        let removeFiles=[]

        if(eventImage.length>0){
            for (let i = 0; i < eventImage.length; i++) {
                formData.append('file', eventImage[i]);
            }
            updateData.eventImage.forEach(element => {
                removeFiles.push(element.name)
            });
            formData.append('remove', JSON.stringify(removeFiles));
            await Axios.post(`/open/file/upload/headerImage&eventImage`, formData, {
            })
            .catch(function (error) {
                toast.error("Event Image Uploaded Failed !", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            });
        }
        
        if(researchImage.length>0){
            formData = new FormData();
            for (let i = 0; i < researchImage.length; i++) { 
                formData.append('file', researchImage[i]);
            }

            removeFiles=[]
            updateData.researchImage.forEach(element => {
                removeFiles.push(element.name)
            });
            formData.append('remove', JSON.stringify(removeFiles));
            await Axios.post(`/open/file/upload/headerImage&researchImage`, formData, {
            })
            .catch(function (error) {
                errorFound = true;
                toast.error("Payment File Uploaded Failed !", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            });
        }

        if(storyImage.length>0){
            formData = new FormData();
            for (let i = 0; i < storyImage.length; i++) { 
                formData.append('file', storyImage[i]);
            }

            removeFiles=[]
            updateData.storyImage.forEach(element => {
                removeFiles.push(element.name)
            });
            formData.append('remove', JSON.stringify(removeFiles));
            await Axios.post(`/open/file/upload/headerImage&storyImage`, formData, {
            })
            .catch(function (error) {
                errorFound = true;
                toast.error("Payment File Uploaded Failed !", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            });
        }

        if(studyImage.length>0){
            formData = new FormData();
            for (let i = 0; i < studyImage.length; i++) { 
                formData.append('file', studyImage[i]);
            }

            removeFiles=[]
            updateData.studyImage.forEach(element => {
                removeFiles.push(element.name)
            });
            formData.append('remove', JSON.stringify(removeFiles));
            await Axios.post(`/open/file/upload/headerImage&studyImage`, formData, {
            })
            .catch(function (error) {
                errorFound = true;
                toast.error("Payment File Uploaded Failed !", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            });
        }

        if(newsBlogImage.length>0){
            formData = new FormData();
            for (let i = 0; i < newsBlogImage.length; i++) { 
                formData.append('file', newsBlogImage[i]);
            }

            removeFiles=[]
            updateData.newsBlogImage.forEach(element => {
                removeFiles.push(element.name)
            });
            formData.append('remove', JSON.stringify(removeFiles));
            await Axios.post(`/open/file/upload/headerImage&newsBlogImage`, formData, {
            })
            .catch(function (error) {
                errorFound = true;
                toast.error("Payment File Uploaded Failed !", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            });
        }

        if(footerImage.length>0){
            formData = new FormData();
            for (let i = 0; i < footerImage.length; i++) { 
                formData.append('file', footerImage[i]);
            }

            removeFiles=[]
            updateData.footerImage.forEach(element => {
                removeFiles.push(element.name)
            });
            formData.append('remove', JSON.stringify(removeFiles));
            await Axios.post(`/open/file/upload/headerImage&footerImage`, formData, {
            })
            .catch(function (error) {
                errorFound = true;
                toast.error("Payment File Uploaded Failed !", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            });
        }

        if(aboutImage.length>0){
            formData = new FormData();
            for (let i = 0; i < aboutImage.length; i++) { 
                formData.append('file', aboutImage[i]);
            }

            removeFiles=[]
            updateData.aboutImage.forEach(element => {
                removeFiles.push(element.name)
            });
            formData.append('remove', JSON.stringify(removeFiles));
            await Axios.post(`/open/file/upload/headerImage&aboutImage`, formData, {
            })
            .catch(function (error) {
                errorFound = true;
                toast.error("Payment File Uploaded Failed !", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            });
        }

        toast.success("Imgaes Updated Successfully !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
        });
    }

    const eventImageChangeEvent = (event) => {
        let documentFile = event.target.files[0];
        setEventImage([...event.target.files]);
        CHelper.convertToWebP(documentFile, setEventImageSrc);
    }

    const studyImageChangeEvent = (event) => {
        let documentFile = event.target.files[0];
        setStudyImage([...event.target.files]);
        CHelper.convertToWebP(documentFile, setStudyImageSrc);
    }

    const researchImageChangeEvent = (event) => {
        let documentFile = event.target.files[0];
        setResearchImage([...event.target.files]);
        CHelper.convertToWebP(documentFile, setResearchImageSrc);
    }

    const aboutImageChangeEvent = (event) => {
        let documentFile = event.target.files[0];
        setAboutImage([...event.target.files]);
        CHelper.convertToWebP(documentFile, setAboutImageSrc);
    }

    const storyImageChangeEvent = (event) => {
        let documentFile = event.target.files[0];
        setStoryImage([...event.target.files]);
        CHelper.convertToWebP(documentFile, setstoryImageSrc);
    }

    const newsBlogImageChangeEvent = (event) => {
        let documentFile = event.target.files[0];
        setNewsBlogImage([...event.target.files]);
        CHelper.convertToWebP(documentFile, setNewsBlogImageSrc);
    }

    const footerImageChangeEvent = (event) => {
        let documentFile = event.target.files[0];
        setFooterImage([...event.target.files]);
        CHelper.convertToWebP(documentFile, setFooterImageSrc);
    }

    const loadAcademicProfileData = async (id) =>{
        await Axios.get(`/file/headerImages`)
            .then((response) => {
                if (response.data.status === 200) {

                    setUpdateData(response.data.headerImages);

                    if (response.data.headerImages.eventImage && response.data.headerImages.eventImage.length > 0) {
                        setEventImageSrc(CHelper.getsimplifyLink(response.data.headerImages.eventImage[0].link))
                    }
                    if (response.data.headerImages.researchImage && response.data.headerImages.researchImage.length > 0) {
                        setResearchImageSrc(CHelper.getsimplifyLink(response.data.headerImages.researchImage[0].link))
                    }
                    if (response.data.headerImages.aboutImage && response.data.headerImages.aboutImage.length > 0) {
                        setAboutImageSrc(CHelper.getsimplifyLink(response.data.headerImages.aboutImage[0].link))
                    }
                    if (response.data.headerImages.newsBlogImage && response.data.headerImages.newsBlogImage.length > 0) {
                        setNewsBlogImageSrc(CHelper.getsimplifyLink(response.data.headerImages.newsBlogImage[0].link))
                    }
                    if (response.data.headerImages.storyImage && response.data.headerImages.storyImage.length > 0) {
                        setstoryImageSrc(CHelper.getsimplifyLink(response.data.headerImages.storyImage[0].link))
                    }
                    if (response.data.headerImages.studyImage && response.data.headerImages.studyImage.length > 0) {
                        setStudyImageSrc(CHelper.getsimplifyLink(response.data.headerImages.studyImage[0].link))
                    }
                    if (response.data.headerImages.footerImage && response.data.headerImages.footerImage.length > 0) {
                        setFooterImageSrc(CHelper.getsimplifyLink(response.data.headerImages.footerImage[0].link))
                    }
                }
            })
    }

    useEffect(async () => {
        loadAcademicProfileData();

        return () => {
            // Cancel any outstanding requests or subscriptions here
        };
    }, []);


    return (
        <Row>
            <Col xl="12" style={{ margin: "auto" }}>
                <Card>
                    <CardBody>
                        <AvForm className="needs-validation" onSubmit={()=>handleSubmit(true)}>
                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <CardBody>
                                            {!props.id && !noPaddingStyle && <CardTitle typeof="h4">REGISTRATION</CardTitle>}
                                            <div className="form-wizard-wrapper wizard clearfix">
                                                <div className="steps customSteps clearfix">
                                                    <ul>
                                                        <NavItem className={classnames({ current: activeTab === 1 })} onClick={() => {
                                                                    setactiveTab(1)
                                                                }}>
                                                            <NavLink
                                                                className={classnames({ current: activeTab === 1 })}
                                                                
                                                            >
                                                                Events
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem className={classnames({ current: activeTab === 2 })}>
                                                            <NavLink
                                                                className={classnames({ active: activeTab === 2 })}
                                                                onClick={() => {
                                                                    setactiveTab(2)
                                                                }}
                                                            >
                                                                Study
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem className={classnames({ current: activeTab === 3 })}>
                                                            <NavLink
                                                                className={classnames({ active: activeTab === 3 })}
                                                                onClick={() => {
                                                                    setactiveTab(3)
                                                                }}
                                                            >
                                                                Research
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem className={classnames({ current: activeTab === 4 })}>
                                                            <NavLink
                                                                className={classnames({ active: activeTab === 4 })}
                                                                onClick={() => {
                                                                    setactiveTab(4)
                                                                }}
                                                            >
                                                                About Us
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem className={classnames({ current: activeTab === 5 })}>
                                                            <NavLink
                                                                className={classnames({ active: activeTab === 5 })}
                                                                onClick={() => {
                                                                    setactiveTab(5)
                                                                }}
                                                            >
                                                                Stories
                                                            </NavLink>
                                                        </NavItem>
                                                            <NavItem className={classnames({ current: activeTab === 6 })}>
                                                            <NavLink
                                                                className={classnames({ active: activeTab === 6 })}
                                                                onClick={() => {
                                                                    setactiveTab(6)
                                                                }}
                                                            >
                                                                News & Blog
                                                            </NavLink>
                                                        </NavItem>
                                                            <NavItem className={classnames({ current: activeTab === 7 })}>
                                                            <NavLink
                                                                className={classnames({ active: activeTab === 7 })}
                                                                onClick={() => {
                                                                    setactiveTab(7)
                                                                }}
                                                            >
                                                                Footer Page
                                                            </NavLink>
                                                        </NavItem>
                                                    </ul>
                                                </div>
                                                <div className="content clearfix">
                                                    <TabContent
                                                        activeTab={activeTab}
                                                        className="body"
                                                    >
                                                        <TabPane tabId={1}>
                                                            <Row>
                                                                <Col md={12}>
                                                                    <Col md={12}>
                                                                        <div className="mb-3" style={{ textAlign: "center" }}>
                                                                            <CardImg className="img-fluid" src={eventImageSrc} alt="" style={{ maxHeight: "350px", minHeight: "220px", width: "auto" }} />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={12}>
                                                                        <div className="mb-3 justify-content-center d-flex flex-row">
                                                                            <label type="button" className="btn btn btn-info col-md-3" style={{ textAlign: "center" }}><i className="fa fa-upload"></i> Update Image<input hidden onChange={eventImageChangeEvent} accept="image/png, image/gif, image/jpeg, image/jpg" type="file" /></label>
                                                                        </div>
                                                                    </Col>
                                                                </Col>
                                                            </Row>
                                                        </TabPane>

                                                        <TabPane tabId={2}>
                                                        <Row>
                                                                <Col md={12}>
                                                                    <Col md={12}>
                                                                        <div className="mb-3" style={{ textAlign: "center" }}>
                                                                            <CardImg className="img-fluid" src={studyImageSrc} alt="" style={{ maxHeight: "350px", minHeight: "220px", width: "auto" }} />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={12}>
                                                                        <div className="mb-3 justify-content-center d-flex flex-row">
                                                                            <label type="button" className="btn btn btn-info col-md-3" style={{ textAlign: "center" }}><i className="fa fa-upload"></i> Update Image<input hidden onChange={studyImageChangeEvent} accept="image/png, image/gif, image/jpeg, image/jpg" type="file" /></label>
                                                                        </div>
                                                                    </Col>
                                                                </Col>
                                                            </Row>
                                                        </TabPane>

                                                        <TabPane tabId={3}>
                                                        <Row>
                                                                <Col md={12}>
                                                                    <Col md={12}>
                                                                        <div className="mb-3" style={{ textAlign: "center" }}>
                                                                            <CardImg className="img-fluid" src={researchImageSrc} alt="" style={{ maxHeight: "350px", minHeight: "220px", width: "auto" }} />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={12}>
                                                                        <div className="mb-3 justify-content-center d-flex flex-row">
                                                                            <label type="button" className="btn btn btn-info col-md-3" style={{ textAlign: "center" }}><i className="fa fa-upload"></i> Update Image<input hidden onChange={researchImageChangeEvent} accept="image/png, image/gif, image/jpeg, image/jpg" type="file" /></label>
                                                                        </div>
                                                                    </Col>
                                                                </Col>
                                                            </Row>
                                                        </TabPane>

                                                        <TabPane tabId={4}>
                                                        <Row>
                                                                <Col md={12}>
                                                                    <Col md={12}>
                                                                        <div className="mb-3" style={{ textAlign: "center" }}>
                                                                            <CardImg className="img-fluid" src={aboutImageSrc} alt="" style={{ maxHeight: "350px", minHeight: "220px", width: "auto" }} />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={12}>
                                                                        <div className="mb-3 justify-content-center d-flex flex-row">
                                                                            <label type="button" className="btn btn btn-info col-md-3" style={{ textAlign: "center" }}><i className="fa fa-upload"></i> Update Image<input hidden onChange={aboutImageChangeEvent} accept="image/png, image/gif, image/jpeg, image/jpg" type="file" /></label>
                                                                        </div>
                                                                    </Col>
                                                                </Col>
                                                            </Row>
                                                        </TabPane>

                                                        <TabPane tabId={5}>
                                                        <Row>
                                                                <Col md={12}>
                                                                    <Col md={12}>
                                                                        <div className="mb-3" style={{ textAlign: "center" }}>
                                                                            <CardImg className="img-fluid" src={storyImageSrc} alt="" style={{ maxHeight: "350px", minHeight: "220px", width: "auto" }} />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={12}>
                                                                        <div className="mb-3 justify-content-center d-flex flex-row">
                                                                            <label type="button" className="btn btn btn-info col-md-3" style={{ textAlign: "center" }}><i className="fa fa-upload"></i> Update Image<input hidden onChange={storyImageChangeEvent} accept="image/png, image/gif, image/jpeg, image/jpg" type="file" /></label>
                                                                        </div>
                                                                    </Col>
                                                                </Col>
                                                            </Row>
                                                        </TabPane>

                                                        <TabPane tabId={6}>

                                                        <Row>
                                                                <Col md={12}>
                                                                    <Col md={12}>
                                                                        <div className="mb-3" style={{ textAlign: "center" }}>
                                                                            <CardImg className="img-fluid" src={newsBlogSrc} alt="" style={{ maxHeight: "350px", minHeight: "220px", width: "auto" }} />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={12}>
                                                                        <div className="mb-3 justify-content-center d-flex flex-row">
                                                                            <label type="button" className="btn btn btn-info col-md-3" style={{ textAlign: "center" }}><i className="fa fa-upload"></i> Update Image<input hidden onChange={newsBlogImageChangeEvent} accept="image/png, image/gif, image/jpeg, image/jpg" type="file" /></label>
                                                                        </div>
                                                                    </Col>
                                                                </Col>
                                                            </Row>
                                                        </TabPane>

                                                        
                                                        <TabPane tabId={7}>

                                                        <Row>
                                                                <Col md={12}>
                                                                    <Col md={12}>
                                                                        <div className="mb-3" style={{ textAlign: "center" }}>
                                                                            <CardImg className="img-fluid" src={footerImageSrc} alt="" style={{ maxHeight: "350px", minHeight: "220px", width: "auto" }} />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={12}>
                                                                        <div className="mb-3 justify-content-center d-flex flex-row">
                                                                            <label type="button" className="btn btn btn-info col-md-3" style={{ textAlign: "center" }}><i className="fa fa-upload"></i> Update Image<input hidden onChange={footerImageChangeEvent} accept="image/png, image/gif, image/jpeg, image/jpg" type="file" /></label>
                                                                        </div>
                                                                    </Col>
                                                                </Col>
                                                            </Row>
                                                        </TabPane>
                                                    </TabContent>
                                                </div>
                                                <div className="actions clearfix">
                                                    {activeTab != 1 &&
                                                        <Button
                                                            style={{ float: "left" }}
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={() => {
                                                                toggleTab(activeTab - 1)
                                                            }}
                                                        >Previous</Button>
                                                    }
                                                    {activeTab === 7 ?
                                                        <Button color="primary" type="submit">
                                                            Submit
                                                        </Button>
                                                        :
                                                        <Link
                                                            to="#"
                                                            className="btn btn-primary"
                                                            onClick={() => {
                                                                toggleTab(activeTab + 1)
                                                            }}
                                                        >Next</Link>
                                                    }
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </AvForm>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default Model
