import React, { useState, useEffect, useRef } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Label,
  CardImg,
  Table
} from "reactstrap"
import { Delete,Edit } from '@mui/icons-material';
import CustomModal from "../Common/CustomModal"
import {
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Select from "react-select"

import Axios from "../../helpers/axios_helper";
import * as SHelper from "../../helpers/session_helper"
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify/dist/react-toastify';
import mediaImage from "../../assets/images/media.jpeg";
const moment = require('moment');

const Model = (props) => {
  const [gradeCenter, setGradeCenter] = useState([]);
  const [courseQuizes, setCourseQuizes] = useState([]);
  const [courseAssignments, setCourseAssignments] = useState([]);
  const [totalQuizPoints, setTotalQuizPoints] = useState(0);
  const [totalAssignmentsPoints, setTotalAssignmentsPoints] = useState(0);

  useEffect(async ()=>{
    if(props.courseId){
      const user = SHelper.getUser();
      window.sLoader();
        // if(SHelper.userHasEditPermission()){
          await Axios.get(`/course/grade-center/${props.courseId}&${user.userDetailId}`)
          .then((response) => {
              if (response.data.status == 200) {
                const firstData = response.data.data[0];
                const quizzes = firstData.payments[0].course.CourseQuizzes.map(({ id, title, point }) => ({ id, title, point }));
                const assignments = firstData.payments[0].course.courseAssignments.map(({ id, courseAssignmentName,courseAssignmentPoint }) => ({ id, courseAssignmentName,courseAssignmentPoint }));

                const quizPoints = firstData.payments.reduce((total, payment) => {
                  return payment.course.CourseQuizzes.reduce((quizTotal, quiz) => {
                      // return quizTotal + (quiz.CourseQuizSubmissions.length ? quiz.point : 0);
                      return quizTotal + quiz.point ;
                  }, total);
                }, 0);

                setTotalQuizPoints(quizPoints);

                const assigmentPoints = firstData.payments.reduce((total, payment) => {
                  return payment.course.courseAssignments.reduce((quizTotal, quiz) => {
                      // return quizTotal + (quiz.CourseQuizSubmissions.length ? quiz.point : 0);
                      return quizTotal + quiz.courseAssignmentPoint ;
                  }, total);
                }, 0);

                setTotalAssignmentsPoints(assigmentPoints);

                setCourseQuizes(quizzes);
                setCourseAssignments(assignments)
                setGradeCenter(response.data.data);
              }
              else {
                setGradeCenter([])
              }
          });
        // }
        window.hLoader();
    }
  },[props.courseId])

  const getQuizMark = (quiz, data) =>{
    try {
      if(data.payments.length>0){
        let score = '-';

        if(data.payments[0].course?.CourseQuizzes.length>0){
          const courseQuiz =  data.payments[0].course.CourseQuizzes.filter(x=>x.id==quiz.id)[0];
          if(courseQuiz.CourseQuizSubmissions.length>0){
            score = courseQuiz.CourseQuizSubmissions[0].score;
          }
        }
        return score;

      }
    } catch (error) {
      console.log(error);
      return '-';
    }
  } 

  const getAssignmentMark = (assignment,data) =>{
    try {
      if(data.payments.length>0){
        let score = '-';
        let total = '-';
        let sum = 0;

        if(data.payments[0].course?.courseAssignments.length>0){
          const courseAssignment =  data.payments[0].course.courseAssignments.filter(x=>x.id==assignment.id)[0];
          if(courseAssignment.courseAssignmentSubmissions.length>0){
            total=courseAssignment.courseAssignmentPoint;
            score = courseAssignment.courseAssignmentSubmissions[0].rubricWeight;
            sum = parseAndSumValues(courseAssignment.courseAssignmentSubmissions[0].rubricResult)
          }
        }
        return {score,total,sum};

      }
    } catch (error) {
      console.log(error);
      return {};
    }
  }

  const parseAndSumValues = (jsonString) => {
    try {
      const obj = JSON.parse(jsonString);
      
      if (typeof obj !== 'object' || obj === null) {
        return 0;
      }
  
      return Object.values(obj).reduce((sum, value) => {
        return sum + (isNaN(parseFloat(value)) ? 0 : parseFloat(value));
      }, 0);
    } catch (e) {
      return 0;
    }
  };

  return (
    <Row>
        <Col xl="12">
          <Card>
            <CardTitle style={{ fontSize: "18px", textAlign: "center", opacity:"0.7" }}>Grade Center</CardTitle>
            <CardBody style={{ paddingTop: "0.5rem" }}>
              <AvForm className="needs-validation" onSubmit={()=>{}}>
                  <Col md={12}>
                      <div className="table-responsive">
                          <Table className="table mb-0" bordered>
                              <thead>
                              <tr>
                                <th rowSpan="2">#</th>
                                <th rowSpan="2">Learner Name</th>
                                {courseQuizes.length>0 && <>
                                  <th colSpan={courseQuizes.length} style={{textAlign:"center"}}>Quizes</th>
                                  {/* <th rowSpan="2">Total Point out of {totalQuizPoints}</th> */}
                                  <th rowSpan="2">Quiz Grade</th>
                                </>}
                                {courseAssignments.length>0 && <>
                                  <th colSpan={courseAssignments.length} style={{textAlign:"center"}}>Assignments</th>
                                  {/* <th rowSpan="2">Total Point out of {totalAssignmentsPoints}</th> */}
                                  <th rowSpan="2">Assignment Grade</th>
                                </>}
                                <th rowSpan="2">Total Grade</th>
                              </tr>
                              <tr>
                                {courseQuizes.map((item,i)=>{
                                  return (
                                    <th key={i}>{item.title} out of {item.point}</th>
                                  )
                                })}
                                {courseAssignments.map((item,i)=>{
                                  return (
                                    <th key={i}>{item.courseAssignmentName} out of {item.courseAssignmentPoint}</th>
                                  )
                                })}
                              </tr>
                              </thead>
                              <tbody>
                              {gradeCenter.map((item,i)=>{
                                    let totalQuizMark = 0;
                                    let totalAssignmentMark = 0;
                                    return (
                                        <tr key={i}>
                                            <th scope="row">{i+1}</th>
                                            <td>{item.academicFullName}</td>
                                            {courseQuizes.map((cq,i)=>{
                                              const mark = getQuizMark(cq,item);
                                              const parsed = parseInt(mark, 10);
                                              totalQuizMark+=isNaN(parsed) ? 0 : parsed;
                                              return (
                                                <th key={i}>{mark}</th>
                                              )
                                            })}
                                            
                                            {courseQuizes.length>0 && <>
                                              <td>{parseFloat((totalQuizMark/totalQuizPoints)*100).toFixed(2)}%</td>
                                            </>}

                                            {courseAssignments.map((ca,i)=>{
                                              const mark = getAssignmentMark(ca,item);
                                              const percentageMark = mark.score!='-'?mark.total*(mark.score/100):mark.score;
                                              totalAssignmentMark+=mark.sum;//isNaN(percentageMark) ? 0 : parseFloat(percentageMark.toFixed(2));
                                              return (
                                                // <th key={i}>{percentageMark} ({mark.score}%)
                                                <th key={i}>{mark.sum}
                                                {/* <th key={i}>{isNaN(percentageMark) ? '-': parseFloat(percentageMark.toFixed(2))}  */}
                                                {/* {!isNaN(percentageMark) && <span style={{fontSize:"10px", fontWeight:"0"}}>({mark.score}%)</span>} */}
                                                </th>
                                              )
                                            })}  
                                              
                                            {courseAssignments.length>0 && <>
                                              <td>{parseFloat((totalAssignmentMark/totalAssignmentsPoints)*100).toFixed(2)}%</td> 
                                            </>}
                                            <td>{(((parseFloat(totalAssignmentMark)+parseFloat(totalQuizMark))/(totalQuizPoints+totalAssignmentsPoints))*100).toFixed(2)}%</td> 
                                        </tr>
                                      )
                                  })}
                              </tbody>
                          </Table>
                      </div>
                  </Col>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
    </Row>
  )
}

export default Model
